const MINT_NFT_ADDRESS = "0x0333f63c8ed192ab07b70d67d18f787198c89fef";
const CONTRACT_ADDRESS = "0xafba8c6b3875868a90e5055e791213258a9fe7a7";
const MINT_NFT_ID = "0";
const ETH_DECIMALS = 10 ** 18;
const VOXO_EMAIL = "info@voxodeus.io";

const EXTERNAL_URLS = {
  REWARDS_FAQ : 'https://cypherverse.notion.site/Rewards-FAQ-a5cc4dfd79774041a56aa828fff56f02',
  REWARDS_FAQ_WITHDRAWAL : 'https://cypherverse.notion.site/Rewards-FAQ-a5cc4dfd79774041a56aa828fff56f02#9f979b315c534e0cb7f28aa89709df49',
  TCV_TERMS : "https://cypherverse.notion.site/Terms-and-Conditions-VoxoDeus-6dc498a7d98e48d7ab158db7958367c7",
  TCV_PRIVACY : "https://cypherverse.notion.site/Privacy-Policy-245fbb87501647c5beef3b049c94b89f",
  LOREDEX : "https://cypherverse.notion.site/LoreDex-34a5314c4bc4448c957a72bb92e8b645",
};

const ARWEAVE = {
  METADATA : 'https://arweave.net/sDte6agJVJO5AxjsDl5o5ra9peuMK4ZU-_PEKvfHN9M',
  GALLERY : 'https://arweave.net/V3LuYRkK-_KBtjbpsqTSKbsrrzt5OFEWEH1ZOhNoQKY',
  NAV_THUMBNAILS : 'https://arweave.net/dL294aZB5vZch8o0cIXky4zbxXeSs7JZoKV9qB3g0Ig',
  HERO_MODELS : `https://arweave.net/8PwCHXZitykEzx2pabuSNPsvWO8nBICOGquI49gojtg/glb/hero`,
  AR_PREVIEW : 'https://arweave.net/19Z75DHhqPPe9_duUMTIvRR02Vgsw2GNJuFd1SU1lO8',
};

const ERROR_MESSAGES = {
  REDIRECT: {
    header: "Redirect",
    message:
      "This part of the site is best experienced on our desktop site, or on OpenSea.",
    actionText: "Go to OpenSea",
    redirectUrl: `https://opensea.io/assets/${MINT_NFT_ADDRESS}/0`,
  },
  METAMASK_NOT_INSTALLED: {
    header: "Connecting your wallet",
    message:
      "To continue, please install MetaMask or visit OpenSea for more supported wallets.",
    actionText: "Go to OpenSea",
    secondaryActionText: "Metamask",
    redirectUrl: `https://opensea.io/assets/${MINT_NFT_ADDRESS}/0`,
    secondaryRedirectUrl: "https://metamask.io/download.html",
  },
  METAMASK_NOT_CONNECTED: {
    header: "Connecting your wallet",
    message:
      "To continue, please unlock MetaMask and approve website access to your account.",
    actionText: "Continue",
    secondaryActionText: "Cancel",
  },
  WRONG_CHAIN_NETWORK: {
    header: "Ethereum Network",
    message: "Please connect to the ‘Ethereum Mainnet’ to continue.",
    actionText: "Continue",
    secondaryActionText: "Cancel",
  },
  INSUFFICIENT_BALANCE: {
    header: "The Funds",
    message: "But you don’t have enough ETH :(",
    actionText: "Ok then",
  },
  TRANSACTION_CANCELLED: {
    header: "Transaction Incomplete",
    message: "It appears you’ve cancelled the transaction.",
    actionText: "Ok",
  },
  TRANSACTION_NOT_GET_THROUGH: {
    header: "Transaction Incomplete",
    message:
      "It’s likely that someone else took your order. Please refresh the page and try again.",
    actionText: "Try Again",
  },
};

export {
  ARWEAVE,
  CONTRACT_ADDRESS,
  ERROR_MESSAGES,
  ETH_DECIMALS,
  EXTERNAL_URLS,
  MINT_NFT_ADDRESS,
  MINT_NFT_ID,
  VOXO_EMAIL,
};
