import React, { useState, useEffect, useContext } from 'react';
import {
  StyledNavBar,
  NavBarItems,
  Logo,
  Nav,
  Links,
  Toggle,
  Divider,
  StyledButton,
  CloseMenuButton,
  AltLink,
  StyledButtonWrapper,
  StyledButtonDropdown,
  Desktop,
  OpenseaButton,
  StyledNavBarDropdownItemButton,
  StyledNavBarDropdownItemButtonWrapper,
  StyledNavBarDropdownItemButtonDropdown,
} from './styles';
import logo from '../../assets/icons/voxodeus_logo.png';
import discord from '../../assets/icons/discord.svg';
import twitter from '../../assets/icons/twitter.svg';
import line from '../../assets/images/divider.svg';
import wallet from '../../assets/icons/wallet.svg';
import chevron_down from 'assets/icons/chevron_down.svg';
import { debounce } from '../../utils/helper';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { ReactComponent as OpenSeaIcon } from 'assets/icons/Logomark-Opensea.svg';
import { AppContext } from 'context/AppContext';

const omegaRoutes = '/okg';
const NavBar = () => {
  const { account, active, deactivate } = useWeb3React();
  const [toggleOn, setToggleOn] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const { isMobile } = useContext(AppContext);

  const history = useHistory();

  const handleScroll = debounce(() => {
    const currScrollPosition = window.pageYOffset;

    setIsVisible(
      (prevScrollPosition > currScrollPosition && prevScrollPosition - currScrollPosition > 70) ||
      currScrollPosition < 10,
    );

    setPrevScrollPosition(currScrollPosition);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPosition, isVisible, handleScroll]);

  let { pathname } = useLocation();

  useEffect(() => {
    if (document.getElementById('main-wrapper')) {
      if (toggleOn) {
        document.getElementById('main-wrapper').style.pointerEvents = 'none';
        document.getElementById('main-wrapper').style.height = '100vh';
        document.getElementById('main-wrapper').style.overflow = 'hidden';
      } else {
        document.getElementById('main-wrapper').style.pointerEvents = 'all';
        document.getElementById('main-wrapper').style.height = '100%';
        document.getElementById('main-wrapper').style.overflow = 'unset';
      }
    }
  }, [toggleOn]);

  const handleDisconnectClick = () => {
    deactivate();
  };
  return (
    <StyledNavBar visible={isVisible} black={pathname.includes(omegaRoutes)}>
      <NavBarItems id='container'>
        <Link
          to='/'
          style={{ marginLeft: 0 }}
          onClick={() => {
            setToggleOn(false);
          }}
        >
          <Logo>
            <img src={logo} alt='vox' />
          </Logo>
        </Link>
        <Nav toggleOn={toggleOn}>
          <NavLink
            to='/gallery'
            onClick={() => {
              setToggleOn(false);
            }}
          >
            Gallery
          </NavLink>
          <Links>
            <div>
              <a class="social" href="https://discord.gg/voxodeus" target="_blank" rel="noopener nofollow noreferrer">
                <img src={discord} alt="Discord" />
              </a>
              <a class="social" href="https://twitter.com/voxodeus" target="_blank" rel="noopener nofollow noreferrer">
                <img src={twitter} alt="Twitter" />
              </a>
            </div>
            <Divider src={line} alt="Divider" />
            <OpenseaButton>
              <a href={`https://opensea.io/collection/voxodeus`} target="_blank" rel="noreferrer">
                <OpenSeaIcon />{' '}
                <div>
                  <span>View on</span>OpenSea
                </div>
              </a>
            </OpenseaButton>
            {active && account && (
              <StyledButtonWrapper>
                <StyledButton
                  iconLeft={wallet}
                  type="secondary"
                  text={account?.substring(0, 14)}
                  customBorderColor="#ffffff33"
                  iconRight={chevron_down}
                />
                <StyledButtonDropdown>
                  <div onClick={handleDisconnectClick}>Disconnect</div>
                </StyledButtonDropdown>
              </StyledButtonWrapper>
            )}
          </Links>
        </Nav>
        <Toggle
          toggleOn={toggleOn}
          onClick={() => {
            setToggleOn(!toggleOn);
          }}
        >
          {toggleOn ? (
            <CloseMenuButton>✕</CloseMenuButton>
          ) : (
            <>
              <span></span>
              <span></span>
              <span></span>
            </>
          )}
        </Toggle>
      </NavBarItems>
    </StyledNavBar>
  );
};

export { NavBar };
