import React, {useEffect, useState} from 'react';
import {getVoxoById} from "../../services/voxosService";

const CharacterModel = ({ voxoId }) => {

  const [listItem, setListItem] = useState(null);

  useEffect(() => {
    fetchVoxo();
  }, [voxoId]);

  const fetchVoxo = () => {
    if (!voxoId) return;
    getVoxoById(voxoId).then((res) => {
      setListItem(res.data);
    });
  };

  const returnValueForTraitType = (type) => {
    if (listItem) {
      const attribute = listItem.attributes.find((x) => x.trait_type === type);
      return attribute ? attribute.value : '';
    }
  };

  return (
      <>
      {listItem ? (
            <div>
              <div className="stats">
                <div className="row">
                  <span className="key">ID</span>
                  <span className="value btn-filter">#{listItem.token_id.toString().padStart(4, '0')}</span>
                </div>
                <div className="row">
                  <span className="key">Specie</span>
                  <span className="value btn-filter">{returnValueForTraitType("#1 Species")}</span>
                </div>
                <div className="row">
                  <span className="key">Faction</span>
                  <span className="value btn-filter">{returnValueForTraitType("#3 Faction")}</span>
                </div>
                <div className="row">
                  <span className="key">Rarity</span>
                  <span className="value btn-filter">{returnValueForTraitType("#2 Rarity")}</span>
                </div>
                <div className="row">
                  <span className="key">Archetype</span>
                  <span className="value btn-filter">{returnValueForTraitType("#4 Archetype")}</span>
                </div>
              </div>
              <div className="viewport">
                <iframe
                    key={voxoId}
                    width="600"
                    height="580"
                    data-faction={returnValueForTraitType("#3 Faction")}
                    data-rarity={returnValueForTraitType("#2 Rarity")}
                    data-archetype={returnValueForTraitType("#4 Archetype")}
                    src={listItem.animation_url}
                    className="on"
                ></iframe>
              </div>
              <div className="gears">
                <div className="slot">
                  <div className="label">Intelligence</div>
                  <strong>+ {returnValueForTraitType("Intelligence")} </strong>%
                </div>
                <div className="slot">
                  <div className="label">Strength</div>
                  <strong>+ {returnValueForTraitType("Strength")} </strong>%
                </div>
                <div className="slot">
                  <div className="label">Heart</div>
                  <strong>+ {returnValueForTraitType("Heart")} </strong>%
                </div>
                <div className="slot">
                  <div className="label">Cool</div>
                  <strong>+ {returnValueForTraitType("Cool")} </strong>%
                </div>
              </div>
            </div>
        ) : null}
        </>
  );
};

export default CharacterModel;
