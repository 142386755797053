export const textTrunc = str => {
  if (str.length > 20) {
    return str.substr(0, 5) + '...' + str.substr(str.length - 5, str.length);
  }
  if (str.length > 15) {
    return str.substr(0, 12) + '...';
  }
  return str;
};

export const accountTrunc = account => {
  return `${account.slice(0, 13)}...${account.slice(account.length - 4)}`;
};

export const fileNameTrunc = fileName => {
  if (fileName.length > 20) {
    return fileName.substr(0, 5) + '...' + fileName.substr(fileName.length - 5, fileName.length);
  }
  return fileName;
};
