import React, { memo } from 'react';
import {
  ExtraItemContainer,
  ExtraItemIcon,
  ExtraItemName,
  ExtraItemRarity,
  ExtraItemSpec,
} from 'containers/CharacterDetailsPage/characterDetailsPage.styled';
import { RarityPoints } from 'containers/CharacterDetailsPage/RarityPoints';

const ExtraItem = ({ spec, rarity, data, rarityPoints }) => {
  return (
    <ExtraItemContainer>
      <ExtraItemName>{data.title}</ExtraItemName>
      <ExtraItemSpec style={{ background: data.color }}>{spec}</ExtraItemSpec>
      {rarity && (
        <ExtraItemRarity>
          {rarity}% Voxos have this <RarityPoints points={rarityPoints} isFull={false} />
        </ExtraItemRarity>
      )}
      <ExtraItemIcon>{data.icon}</ExtraItemIcon>
    </ExtraItemContainer>
  );
};
export default memo(ExtraItem);
