import React, { useState } from 'react';
import {
  AttributesContainer,
  BaseScene,
  DetailsControls,
  DetailsSpecies,
  ShowInARButton,
  VerticalSeparatorCotainer,
  VoxoAttributes,
  VoxoBaseScene,
  VoxoControlsRight,
  VoxoDataContainer,
  VoxoDescription,
  VoxoHeader,
  VoxoText,
  VoxoTextMobile,
  VoxoRarity,
  BasePoints,
  BasePointsHeader,
  RarityRankWrapper,
  RarityRank,
  CalculationBreakdown,
  Desktop,
  Mobile,
  Value,
} from '../characterDetailsPage.styled';
import { ReactComponent as VerticalSeparator } from 'assets/icons/vertical_separator.svg';
import { ReactComponent as OpenSeaIcon } from 'assets/icons/Logomark-Opensea.svg';
import { ReactComponent as FullscreenIcon } from 'assets/icons/fullscreen_2.svg';
import { ReactComponent as ViewInARIcon } from 'assets/icons/view_in _AR_icon.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye_icon.svg';
import { padLeadingZeros, withThousandsSeparator } from 'utils/numberFormat';
import { CONTRACT_ADDRESS } from 'utils/constants';
import { Attributes } from './Attributes';
import Extras from './Extras';
import { archetypes } from 'sections/characters-section/Archetypes/archetypesArray';
import { FullScreenIframe } from 'components/FullScreenIframe/FullScreenIframe';
import { Gear } from './Gear';

import { QRCodeSectionDetailsPage, QRCodeWrapper, QRHeader, QRSubHeader } from 'containers/AR-Viewer/arviewer.styled';
import QRCode from 'react-qr-code';
import { RarityPoints } from '../RarityPoints';

const Scene = ({ voxoData }) => (
  <VoxoBaseScene>
    <div className="header">BASE SCENE</div>
    <BaseScene>
      <Value>{voxoData.attributes.find(trait => trait.trait_type == 'Scene').value}</Value>
      <Mobile className="points">
        <RarityPoints points={voxoData.rarity.traits.Scene} isFull />
      </Mobile>
    </BaseScene>
    <Desktop>
      <RarityPoints points={voxoData.rarity.traits.Scene} isFull />
    </Desktop>
  </VoxoBaseScene>
);
const Rarity = ({ voxoData }) => (
  <RarityRankWrapper>
    <RarityRank>
      <div>
        rarity rank:<span>{voxoData.rarity.rank}</span>
      </div>
      <div>
        rarity SCORE:<span>{withThousandsSeparator(Math.round(voxoData.rarity.score))}</span>
      </div>
    </RarityRank>
  </RarityRankWrapper>
);
const VoxoData = ({ voxoData }) => {
  voxoData.traits = voxoData.attributes.reduce((o, cur) => ({ ...o, [cur.trait_type]: cur.value }), {});
  voxoData.profile = archetypes[voxoData.traits['#1 Species'].toLowerCase()].find(
    a => a.name.toLowerCase() === voxoData.traits['#4 Archetype'].toLowerCase(),
  );
  const [showFullscreen, toggleShowFullscreen] = useState(false);
  const Legend = legend => {
    return Array.isArray(legend)
      ? legend.map((el, index) =>
          el.text === '' ? (
            <p></p>
          ) : (
            <div key={index} style={{ marginTop: '5px', fontStyle: el.style || 'unset' }}>
              {el.text}
            </div>
          ),
        )
      : legend;
  };
  return (
    <VoxoDataContainer>
      <VerticalSeparatorCotainer>
        <VerticalSeparator style={{ marginRight: '42px' }} />
      </VerticalSeparatorCotainer>
      <div style={{ position: 'relative', top: '130px', width: '100%' }}>
        <DetailsSpecies>{voxoData.traits['#1 Species']}</DetailsSpecies>
        <DetailsControls>
          <VoxoRarity>
            <span>voxo #{padLeadingZeros(voxoData.token_id, 4)}</span>
            {voxoData.rarity?.base_points && (
              <BasePoints>
                <BasePointsHeader>BASE points</BasePointsHeader>
                <RarityPoints points={voxoData.rarity.base_points} isFull />
              </BasePoints>
            )}
          </VoxoRarity>
          <VoxoControlsRight>
            <div>
              <a
                href={`https://opensea.io/assets/${CONTRACT_ADDRESS}/${voxoData.token_id}`}
                target="_blank"
                rel="noreferrer"
              >
                <OpenSeaIcon /> View on OpenSea
              </a>
            </div>
            <div onClick={() => toggleShowFullscreen(true)}>
              <FullscreenIcon className="full-screen" />
              View full screen
            </div>
            <ShowInARButton className="extra-padding">
              <a href={`/ar/${voxoData.token_id}/${voxoData.code_name}`} target="_blank" rel="noreferrer">
                <ViewInARIcon />
                View in AR
              </a>
              <QRCodeSectionDetailsPage>
                <QRHeader>VIEW ON MOBILE</QRHeader>
                <QRSubHeader>Scan QR code</QRSubHeader>
                <QRCodeWrapper>
                  <QRCode
                    size={65}
                    value={`${window.location.origin}/ar/${voxoData.token_id}/${voxoData.code_name}`}
                    fgColor="#2A4060"
                  />
                </QRCodeWrapper>
              </QRCodeSectionDetailsPage>
            </ShowInARButton>
          </VoxoControlsRight>
        </DetailsControls>
        {voxoData.profile && (
          <VoxoAttributes>
            <VoxoDescription>
              <img src={voxoData.image} />
              <div>
                <VoxoHeader>
                  <span>{voxoData.traits['#4 Archetype']}</span>
                  <p>'{voxoData.profile && voxoData.profile.prof ? voxoData.profile.prof : ''}'</p>
                </VoxoHeader>
                <VoxoText>
                  {voxoData.profile && voxoData.profile.legend ? Legend(voxoData.profile.legend) : ''}
                </VoxoText>
                <Desktop>
                  <Scene voxoData={voxoData} />
                  <Rarity voxoData={voxoData} />
                </Desktop>
              </div>
            </VoxoDescription>
            <VoxoTextMobile>
              {voxoData.profile && voxoData.profile.legend ? Legend(voxoData.profile.legend) : ''}
            </VoxoTextMobile>
            <Mobile>
              <Scene voxoData={voxoData} />
              <Rarity voxoData={voxoData} />
            </Mobile>
          </VoxoAttributes>
        )}
        <AttributesContainer>
          <Attributes
            title="Attributes"
            rarity={voxoData.rarity}
            keys={[
              { name: 'SPECIES', value: voxoData.traits['#1 Species'], keyName: '#1 Species' },
              { name: 'RARITY', value: voxoData.traits['#2 Rarity'], keyName: '#2 Rarity' },
              { name: 'FACTION', value: voxoData.traits['#3 Faction'], keyName: '#3 Faction' },
              { name: 'ARCHETYPE', value: voxoData.traits['#4 Archetype'], keyName: '#4 Archetype' },
            ]}
            isButton
          />
          <Attributes
            title="Boosts"
            rarity={voxoData.rarity}
            keys={[
              { name: 'INTELLIGENCE', value: `+ ${voxoData.traits['Intelligence']}%` },
              { name: 'STRENGTH', value: `+ ${voxoData.traits['Strength']}%` },
              { name: 'HEART', value: `+ ${voxoData.traits['Heart']}%` },
              { name: 'COOL', value: `+ ${voxoData.traits['Cool']}%` },
            ]}
          />
          <Gear voxoData={voxoData} />
        </AttributesContainer>
        <Extras voxoData={voxoData} />
      </div>
      {showFullscreen && <FullScreenIframe link={voxoData.animation_url} toggleShowFullscreen={toggleShowFullscreen} />}
    </VoxoDataContainer>
  );
};
export default VoxoData;
