import {
  FastNavigationContainer,
  FastNavigationItem,
  FastNavigationItemShort,
  ImageWrapper,
  Navigation,
  NavigationBlock,
  NavigationData,
} from 'containers/CharacterDetailsPage/characterDetailsPage.styled';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/icons/Navigation/nav_back.svg';
import { ReactComponent as NextIcon } from 'assets/icons/Navigation/nav_next.svg';
import { ReactComponent as GridIcon } from 'assets/icons/Navigation/grid.svg';
import { padLeadingZeros } from 'utils/numberFormat';

const FastNavigation = ({ voxoNav }) => {
  return (
    <FastNavigationContainer>
      <FastNavigationItem>
        {voxoNav[0] ? (
          <NavLink to={`/voxo/${voxoNav[0].id}`}>
            <Navigation>
              <ImageWrapper>
                <img src={voxoNav[0].image} />
              </ImageWrapper>
              <NavigationBlock>
                <BackIcon />
                <NavigationData>
                  <span>PREV</span>
                  <p>#{padLeadingZeros(voxoNav[0].id, 4)}</p>
                </NavigationData>
              </NavigationBlock>
            </Navigation>
          </NavLink>
        ) : null}
      </FastNavigationItem>
      <FastNavigationItemShort>
        <NavLink to="/gallery">
          <NavigationData>
            <GridIcon className="all-voxos-grid" />
            <span className="all-voxos">ALL VOXOS</span>
          </NavigationData>
        </NavLink>
      </FastNavigationItemShort>
      <FastNavigationItem>
        {voxoNav[1] ? (
          <NavLink to={`/voxo/${voxoNav[1].id}`}>
            <Navigation className="last">
              <NavigationBlock>
                <NavigationData>
                  <span>NEXT</span>
                  <p>#{padLeadingZeros(voxoNav[1].id, 4)}</p>
                </NavigationData>
                <NextIcon />
              </NavigationBlock>
              <ImageWrapper>
                <img src={voxoNav[1].image} />
              </ImageWrapper>
            </Navigation>
          </NavLink>
        ) : null}
      </FastNavigationItem>
    </FastNavigationContainer>
  );
};
export default memo(FastNavigation);
