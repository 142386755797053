import styled from 'styled-components';
import * as size from 'assets/CSSPalette/sizes';
import * as color from 'assets/CSSPalette/colors';
import media from 'themes/media';
import { QRCodeSectionDetailsPage } from 'containers/AR-Viewer/arviewer.styled';

export const CharacterDetailsPageWrapper = styled.main`
  position: relative;
  top: 100px;
  color: #fff;
  font-size: 25px;

  padding-bottom: 101px;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    padding: 0 24px;
    padding-bottom: 101px;
  }
  ${media.mobile`
    padding:0;
    padding-bottom: 101px;
    height: ${({ showFilters }) => (showFilters ? '100vh' : 'unset')};
    overflow: ${({ showFilters }) => (showFilters ? 'hidden' : 'unset')};
 `}
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${size.CONTAINER_MAX_WIDTH};
  margin: 30px auto;
`;
export const OwnerDataItem = styled.div`
  padding: 0 35px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #bdb9dd;
  font-weight: bold;
  font-size: 11px;
  display: flex;
  align-items: center;
  line-height: 40px;
  span {
    opacity: 0.5;
  }
  button {
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-family: 'IBM Plex Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    position: relative;
    top: 12px;
    height: 40px;

    &:after {
      display: none;
      position: absolute;
      top: 22px;
      content: 'Click to copy address';
      color: #ddd;
      font-size: 12px;
      white-space: nowrap;
      transition: all 0.5s ease-out;
      background: rgba(42, 64, 96, 0.5);
      box-shadow: 1px 2px 80px rgba(11, 21, 40, 0.5);
      border-radius: 2px;
      padding: 7px 10px;
      font-family: 'IBM Plex Sans', sans-serif;
      letter-spacing: 0.01em;
    }
    &:hover {
      &:after {
        display: block;
      }
    }
    &:focus {
      outline: none;
      &:after {
        content: 'Copied!';
        color: #56f6ce;
        font-weight: bold;
      }
    }
  }
  ${media.mobile`
    display: flex;
    flex-direction:column;
    padding: 0px 35px 0 0px;
    align-items: flex-start;
    span {
      line-height: unset;
      height:39px;
    }
    button {
      top:-7px;
      height:unset;
      /* margin-top: 10px; */
      margin-left: 0;
    }
  `}
`;
export const OwnerData = styled.div`
  height: 40px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -12px;
  background: #0e1c34;
  border-radius: 5px;
  z-index: 1;
  /* font-family: IBM Plex Sans; */

  ${media.mobile`
    width: 100%;
    height:78px;
    bottom: -70px;
  `}
`;
export const VoxoPreview = styled.div`
  height: 75vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    pointer-events: ${({ showOverlay }) => (showOverlay ? 'none' : 'unset')};
    background-color: #0c182e;
    transition: opacity 0.3s ease-out;

    &.on {
      pointer-events: auto;
    }
  }

  .togglebig {
    position: absolute;
    left: calc(50% - 50px);
    bottom: 100px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      left: -100px;
      top: -100px;
      width: 300px;
      height: 300px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #0b1528 0%,
        rgba(11, 21, 40, 0.489861) 55.43%,
        rgba(11, 21, 40, 0) 100%
      );
    }

    &::after {
      content: 'Tap to interact';
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      color: #bdb9dd;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0.01em;
      white-space: nowrap;
      text-align: center;
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;
      opacity: 1;
      z-index: 1;
      opacity: 0.7;
    }
    > svg {
      z-index: 2;
    }
    img {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    @media screen and (max-width: 600px) {
      bottom: auto;
      top: Max(calc(70vh - 200px), 380px);
    }
  }

  .toggleinteract {
    position: absolute;
    z-index: 1;
    right: 0px;
    width: 100px;
    height: 100px;
    bottom: 0;
    margin: 0px;
    cursor: pointer;

    @media screen and (min-width: 2480px) {
      right: calc((100% - #{$max-width}) / 2);
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: -30px;
    //   top: -33px;
    //   width: 100px;
    //   height: 100px;
    // }

    &::after {
      content: 'Pause interactivity';
      position: absolute;
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%) translateX(10px);
      padding: 7px 10px;
      border-radius: 2px;
      color: $white;
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: 0.01em;
      background-color: rgb(42 64 96 / 50%);
      white-space: nowrap;
      opacity: 0;
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    }

    &:hover {
      &::after {
        transform: translateY(-50%) translateX(0);
        opacity: 1;
      }
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transition: opacity 0.15s ease-out;
    }

    .i {
      opacity: 0;
    }

    &.off {
      .p {
        opacity: 0;
      }
      .i {
        opacity: 1;
      }

      &::before {
        content: 'Interact';
      }
    }
  }

  .togglefs {
    position: absolute;
    right: calc((100% - #{$max-width}) / 2);
    bottom: 1rem;
    width: 88px;
    height: 88px;
    margin-right: -24px;
    z-index: 10;
    opacity: 0.7;
    transition: opacity 0.3s ease-out;

    @media screen and (min-width: 1600px) {
      right: calc((100% - #{$max-width}) / 4);
    }

    &::before {
      content: 'Click to \Ago full screen';
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-60%);
      font-size: 12px;
      text-align: right;
      white-space: pre;
      color: rgba($void7, 1);
    }

    img {
      display: block;
    }

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1300px) {
    .stats {
      left: 30px;
    }

    .sibnav {
      right: 30px;
    }

    .togglefs {
      right: 30px;
    }
  }

  @media screen and (max-width: 1160px) {
    .toggleinteract {
      right: calc((100% - 580px) / 2);
    }
  }

  @media screen and (max-width: 800px) {
    .stats {
      left: 0;
      top: auto;
      bottom: 30px;
      transform: none;
    }

    .sibnav {
      .prev,
      .next {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }

    .toggle {
      bottom: 30px;
      right: 10px;
    }
  }

  @media screen and (max-width: 600px) {
    align-items: flex-start;

    .stats {
      position: static;
      margin-top: 40px;
    }

    .sibnav {
      top: Max(620px, calc(70vh + 40px));
    }

    .toggleinteract {
      left: 30px;
      top: 10px;
    }

    .togglefs {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    .stats {
      transform-origin: left bottom;
      transform: scale(0.9);
    }
  }
`;

export const LoadingWrapper = styled.div`
  height: 80px;
  border-radius: 10px;
  background: #0e1c34;
  display: flex;
  align-items: center;
  padding: 0 42px;
  text-shadow: 1px 2px 10px #d7597b;
  margin: 75px 42px;
  font-size: 16px;
  > svg {
    width: 20px;
    margin-right: 16px;
  }
`;
export const BackButton = styled.button`
  border: none;
  background: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  stroke: #fff;
  transition: all 0.3s ease-out;
  font-family: IBM Plex Sans;
  &:hover {
    color: ${color.VOID_8};
    stroke: ${color.VOID_8};
  }
  > img {
    margin-right: 8px;
  }
`;
export const VoxoId = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  ${media.mobile`
  margin-right 16px;
 `}
`;
export const HorizontalLine = styled.div`
  width: 65.41px;
  height: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
  margin-right: 30px;
  ${media.mobile`
    width: 20px;
 `}
`;
export const VerticalSeparatorCotainer = styled.div`
  ${media.mobile`
   display:none;
 `}
`;

export const VoxoDataContainer = styled.div`
  max-width: ${size.CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  padding-bottom: ${size.FOOTER_HEIGHT};
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  position: relative;
  top: -9px;
  ${media.mobile`
  margin: 30px 16px;
  padding-bottom: 120px;
 `}
`;
export const DetailsSpecies = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  color: ${color.VOID_8};
  font-family: 'Outage';
`;
export const DetailsControls = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  font-family: 'Outage';
  ${media.mobile`
   flex-direction: column;
   align-items: flex-start;
  `}
`;
export const BigSvgWrapper = styled.div`
  height: 27px;
  width: 38px;
  > svg {
    left: -24px;
    top: -24px;
  }
`;
export const VoxoControlsRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  font-family: IBM Plex Sans;
  > div {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 16px;
    border-radius: 20px;
    height: 40px;
    &:hover {
      background: rgb(42 64 96 / 30%);
      a {
        opacity: 1;
      }
    }
    svg {
      margin-right: 10px;
      &.full-screen {
        position: relative;
      }
    }
    &.extra-padding {
      svg {
        margin-right: 8px;
      }
    }
  }
  a {
    display: flex;
    align-items: center;
    opacity: 0.7;
  }
  ${media.mobile`
    margin-top: 20px;
  `}
`;

export const ShowInARButton = styled.div`
  @media screen and (min-width: 800px) {
    &:hover {
      ${QRCodeSectionDetailsPage} {
        display: flex;
      }
    }
  }
`;
export const VoxoAttributes = styled.div`
  padding: 42px 24px;

  ${media.mobile`
  padding: 20px 0px 42px 0;
`}
`;
export const VoxoHeader = styled.div`
  display: flex;
  align-items: center;
  ${media.mobile`
    flex-direction:column;
    align-items: flex-start;
  `}
  > span {
    font-family: 'Outage';
    font-size: 27px;
    font-weight: 700;
    line-height: 30px;
    padding-right: 15px;
    text-transform: lowercase;
    ${media.mobile`
      font-size: 18px;
    `}
  }
  > p {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    padding-left: 15px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: italic;
    ${media.mobile`
      border-left:none;
      padding-left: 0px;
      font-size: 18px;
    `}
  }
`;
export const VoxoText = styled.div`
  color: ${color.TRON_6};
  font-size: 16px;
  max-width: 500px;
  font-weight: 400;
  margin-top: 18px;
  line-height: 24px;
  ${media.mobile`
  display:none;
`}
`;
export const VoxoTextMobile = styled(VoxoText)`
  display: none;
  ${media.mobile`
    display:block;
    max-width: unset;
    padding: 0 16px;
  `}
`;
export const VoxoDescription = styled.div`
  display: flex;
  align-items: center;
  > img {
    height: 230px;
    margin-right: 80px;
    ${media.mobile`
    height: 140px;
    margin-right: 40px;
  `}
  }
  ${media.mobile`
    margin-bottom: 40px;
  `}
`;
export const AttributesContainer = styled.div`
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  ${media.mobile`
    flex-direction:column;
  `}
`;
export const AttributesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`
    margin-bottom: 42px;
  `}
`;
export const SectionTitle = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
`;
export const AttributesList = styled.div`
  min-height: 170px;
  padding-left: 18px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  min-width: 253px;
  display: grid;
`;
export const AttributeLine = styled.div`
  display flex;
  align-items:center;
  margin-top:5px;
  font-size:11px;
`;
export const AttributeName = styled.div`
  display flex;
  align-items:center;
  margin-top:5px;
  color: ${color.TRON_6};
  width:115px;
  letter-spacing: 0.1em;
  font-weight: bold;
`;
export const AttributeValue = styled.div`
  display flex;
  align-items:center;
  background:#0E1C34;
  padding: 10px 15px;
  white-space: nowrap;
  font-size: 14px;
`;

export const AttributeButton = styled(AttributeValue)`
  &:hover {
    cursor: pointer;
    background: rgba(14, 28, 52, 0.2);
  }
`;

export const AttributeTag = styled.span`
  margin-top: 5px;
  background: rgba(42, 64, 96, 0.5);
  border-radius: 200px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  width: fit-content;
`;
export const ExtrasContainer = styled.div`
  width: 100%;
  background: #0e1c34;
  border-radius: 20px;
  padding: 60px 0;
  margin-top: 60px;
  ${media.mobile`
  background: none;
  padding: 20px 16px 60px 16px;
  margin-top:0px;
  `}
`;
export const Title = styled.div`
  text-align: center;
  background: linear-gradient(269deg, #f1aebb 1.22%, #8c86ff 98.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Outage';
`;
export const ExtrasList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  ${media.mobile`
    flex-direction:column;
  `}
`;
export const ExtraItemContainer = styled.div`
  border-radius: 10px;
  width: 28%;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 25px 0 20px 35px;
  position: relative;
  margin-top: 42px;
  ${media.mobile`
    width: 100%;
  `}
`;
export const ExtraItemName = styled.div`
  color: ${color.TRON_6};
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;
export const ExtraItemSpec = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;
  border-radius: 200px;
  width: fit-content;
  padding: 8px 20px;
`;
export const ExtraItemRarity = styled.div`
  color: ${color.TRON_6};
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;
export const ExtraItemIcon = styled.div`
  position: absolute;
  right: 24px;
  top: -10px;
`;
export const FastNavigationContainer = styled.div`
  width: 100%;
  height: 170px;
  background: #2a4060;
  margin-top: 120px;
  display: flex;
  align-items: center;
  ${media.mobile`
    margin-top: 0px;
  `}
`;
export const NavigationBlock = styled.div`
  display: flex;
  z-index: 1;
`;
export const NavigationData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  z-index: 1;
  span {
    color: ${color.TRON_6};
    font-size: 11px;
    font-size: 700;
    font-weight: bold;
    letter-spacing: 0.1em;
    &.all-voxos {
      text-align: center;
    }
  }
  p {
    color: #fff;
    font-family: 'Outage';
    font-size: 17px;
    font-size: 700;
  }
`;
export const FastNavigationItem = styled.div`
  width: 37%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 27px;
  fill: #fff;
  &:last-child {
    ${NavigationData} {
      align-items: flex-end;
    }
  }
  a {
    height: 100%;
  }
  img {
    height: 100%;
  }
  &:hover {
    * {
      &.all-voxos-grid,
      &.all-voxos {
        fill: ${color.VOID_NEON};
        color: ${color.VOID_NEON};
      }
    }
  }
  transition: all 0.3s ease-out;
`;

export const FastNavigationItemShort = styled(FastNavigationItem)`
  width: 26%;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  height: 70px;
  a {
    height: 70px;
  }
  ${NavigationData} {
    align-items: center;
    span {
      margin-top: 12px;
    }
  }
`;
export const Navigation = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  stroke: #fff;

  ${media.mobile`
    flex-direction:column;
    justify-content:center;
    &.last{
      flex-direction: column-reverse;
    }
  `}

  img {
    transition: all 0.1s ease-out;
    ${media.mobile`
      margin: 0;
    `}
  }
  &:hover {
    * {
      color: ${color.VOID_NEON};
      &.all-voxos-grid {
        fill: ${color.VOID_NEON};
      }
    }
    img {
      transform: scale(1.4);
    }
  }
`;

export const Value = styled.div`
  color: #fff;
  font-weight: 500;
  background: #0e1c34;
  border-radius: 0px 2.19178px 2.19178px 0px;
  padding: 10px 14px;
  font-size: 14px;
`;
export const BaseScene = styled.div`
  display: flex;
  flex-direction: column;
`;
export const VoxoBaseScene = styled.div`
  color: #778bb0;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 11px;
  margin-top: 30px;
  ${media.mobile`
  align-items: flex-start;
  padding: 0 16px;
  `}
  .header {
    ${media.mobile`
  padding-top: 12px;
  `}
  }
  > div {
    &:first-child {
      margin-right: 18px;
    }
    .points {
      margin-top: 12px;
    }
  }
`;
export const GearItem = styled.div`
  display: flex;
  align-items: center;
  background: rgba(42, 64, 96, 0.5);
  border-radius: 200px;
  height: 34px;
  margin: 10px 0px;
  padding: 8px 20px;
  font-size: 14px;
  color: #778bb0;
  letter-spacing: 0.01em;
  width: fit-content;
  > span {
    margin-left: 5px;
    font-weight: 500;
    color: #fff;
  }
`;
export const RarityPointsValue = styled.span`
  color: #56f6ce;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  font-family: 'IBM Plex Sans', sans-serif;
  margin-left: 8px;
  font-weight: 400;
`;
export const GearItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const BasePoints = styled.div`
  display: flex;
  align-items: center;
  line-height: 17px;
`;
export const BasePointsHeader = styled.div`
  color: #778bb0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 12px;
`;
export const VoxoRarity = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RarityRankWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 45px 0;
  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
  `}
`;
export const CalculationBreakdown = styled.div`
  color: rgb(255 255 255 / 70%);
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-left: 26px;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 8px;
  }
  cursor: pointer;
  &:hover {
    color: #56f6ce;
    > a {
      text-decoration: underline;
    }
  }
  transition: color 0.2s ease-out;

  ${media.mobile`
    margin-left:0px;
    margin-top:20px;
  `}
`;
export const RarityRank = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #56f6ce;
  padding: 10px 30px;
  border-radius: 5px;
  color: rgb(14 28 52 / 90%);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 700;
  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    background: unset;
    padding: 0;
  `}
  span {
    color: #0b1528;
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
    line-height: 130%;
  }
  > div {
    display: flex;
    align-items: center;
    background: #56f6ce;
    border-radius: 5px;
    ${media.mobile`
      padding: 10px 30px;
    &:not(:first-child) {
      margin-top: 10px;
    }
    `}
    &:not(:first-child) {
      margin-left: 40px;
      ${media.mobile`
        margin-left: 0px;
      `}
    }
  }
`;

export const Desktop = styled.span`
  display: flex;
  flex-direction: column;
  ${media.mobile`
    display:none;
`}
`;
export const Mobile = styled.span`
  display: none;
  ${media.mobile`
    display:flex;
    flex-direction: column;
`}
`;
export const ImageWrapper = styled.div`
  width: 177px;
  height: 114px;
  background: #0e1c34;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  ${media.mobile`
  width: 84px;
  height: 54px;
`}
`;
