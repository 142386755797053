import React, { useState, useEffect, useCallback, useRef } from "react";

const CypherverseSection = ({ addClassBySection, sectionClass }) => {
  var sectionElement = useRef();
  const [y, setY] = useState(window.scrollY);

  // Play animation when section is being shown
  const handleScroll = useCallback(() => {
    var offset =
      sectionElement?.current?.getBoundingClientRect()?.top -
      window.innerHeight / 2;
    if (offset < 0) addClassBySection(5, "play ");
    setY(window.scrollY);
  }, [y]);

  // Scroll listener
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <section
      ref={sectionElement}
      id="cypherverse"
      className={`world ${sectionClass}`}
    >
      <div className="container">
        <div className="content-inner">
          <p className="section-label">THE WORLD</p>
          <h2>ENTER THE Cypherverse</h2>
          <p>
            Eons ago, the Omega programmed the Cypherverse into being. A
            swirling sea of hypercubes born from a neural nebula and pulsing
            with potential for life. To guide it, the Omega created the
            Ascendii, a council of 8 luminary beings imbued with different
            aspects of the Omega’s power. Under their collective guidance,
            society flourished across the Cypherverse.
          </p>

          <p>Then, the Omega vanished.</p>

          <p>
            In their absence, the Ascendii became uncertain about how to use the
            Cypherverse’s most precious resource, NRG (Neural Radiance Gas).
            Society split into 4 factions: The Shen Tanks, Polarion,
            Corpo Vendrix, and Luma Systemica - and though their
            ideals differ, each wishes to unite the Cypherverse under one collective protocol.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CypherverseSection;
