import React, { useState, useEffect } from 'react';
import useDebounce from 'hooks/useDebounce';
import SearchIcon from 'assets/icons/search_icon.svg';
import CancelIcon from 'assets/icons/cancel_icon.svg';
import {
  GalleryContainer,
  GalleryData,
  PageTitle,
  ScrollButtonContainer,
  ScrollTopButton,
  VoxoIdSearch,
  VoxoIdSearchContainer,
  VoxoIdSearchControls,
  VoxoIdWrapper,
  VoxoSearchHash,
  VoxosLength,
} from './gallery.styled';
import VoxoGallery from './VoxoGallery';
import voxos from '../../consts/gallery/all';

const GalleryPage = () => {
  const [voxosArr, setVoxosArr] = useState(voxos.slice(0, 48));
  const [voxosArrShowing, setVoxosArrShowing] = useState(48);
  const [voxoIdSearch, setVoxoIdSearch] = useState('');
  const [voxoSearchLength, setVoxoSearchLength] = useState(48);
  const [voxoSearchResult, setVoxoSearchResult] = useState([]);

  const debouncedSearchTerm = useDebounce(voxoIdSearch, 800);
  useEffect(() => {
    if (voxoIdSearch) {
      setVoxoSearchLength(voxos.filter((voxo) => (String(voxo.id)).includes(String(voxoIdSearch))).length);
      setVoxoSearchResult(voxos.filter((voxo) => (String(voxo.id)).includes(String(voxoIdSearch))));
      setVoxosArr(voxos.filter((voxo) => (String(voxo.id)).includes(String(voxoIdSearch))).slice(0, 48));
    } else {
      setVoxosArr(voxos.slice(0, 48));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const all = voxos.length;
    if (voxoIdSearch) {
      if (voxosArrShowing <= voxoSearchLength) {
        setVoxosArr(voxoSearchResult.slice(0, voxosArrShowing));
      } else {
        setVoxosArr(voxoSearchResult);
      }
    } else {
      if (voxosArrShowing <= all) {
        setVoxosArr(voxos.slice(0, voxosArrShowing));
      } else {
        setVoxosArr(voxos);
      }
    }
  }, [voxosArrShowing]);

  const [inputIsFocused, toggleInputIsFocused] = useState(false);

  const LIMIT = 48;

  const showMoreData = () => {
    if (voxoIdSearch) {
      if (voxosArrShowing < voxoSearchLength) {
        setVoxosArrShowing(voxosArrShowing + LIMIT);
      }
    } else {
      if (voxosArrShowing < voxos.length) {
        setVoxosArrShowing(voxosArrShowing + LIMIT);
      }
    }
  };

  return (
    <GalleryContainer>
      <PageTitle>Gallery</PageTitle>
      <VoxoIdSearchContainer>
        <VoxoIdWrapper>
          {!inputIsFocused && !voxoIdSearch ? <span>Search by Voxo #</span> : null}
          {inputIsFocused || voxoIdSearch ? <VoxoSearchHash>#</VoxoSearchHash> : null}
          <VoxoIdSearch
            value={voxoIdSearch ? voxoIdSearch || '' : ''}
            onFocus={() => toggleInputIsFocused(true)}
            onBlur={() => toggleInputIsFocused(false)}
            onChange={({ target: { value } }) => setVoxoIdSearch(value)}
          />
          <VoxoIdSearchControls
            onClick={() => {
              if (voxoIdSearch && voxoIdSearch) {
                setVoxoIdSearch('');
              }
            }}
            isEmpty={!voxoIdSearch}
            src={!voxoIdSearch ? SearchIcon : CancelIcon}
            alt=""
          />
        </VoxoIdWrapper>
        {voxosArr ? (
          <VoxosLength>
            Showing <b>{voxosArr.length}</b> of {voxoIdSearch ? voxoSearchLength : voxos.length} voxos
          </VoxosLength>
        ) : null}
      </VoxoIdSearchContainer>
      <GalleryData>
        {voxosArr.length ? (
          <VoxoGallery
            voxos={voxosArr}
            showMoreData={showMoreData}
            hasMore={voxosArrShowing < (voxoIdSearch ? voxoSearchLength : voxos.length)}
            dataLength={voxoIdSearch ? voxoSearchLength : voxos.length}
          />
        ) : null}
        <ScrollButtonContainer>
          <ScrollTopButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
        </ScrollButtonContainer>
      </GalleryData>
    </GalleryContainer>
  );
};
export default GalleryPage;
