import React, { useState, useEffect, useCallback, useRef } from "react";

const GlanceSection = ({ addClassBySection, sectionClass }) => {
  var sectionElement = useRef();
  const [y, setY] = useState(window.scrollY);

  // Play animation when section is being shown
  const handleScroll = useCallback(() => {
    var offset =
      sectionElement?.current?.getBoundingClientRect()?.top -
      window.innerHeight / 2;
    if (offset < 0) addClassBySection(3, "play ");
    setY(window.scrollY);
  }, [y]);

  // Scroll listener
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <section
      ref={sectionElement}
      id="glance"
      className={`glance ${sectionClass}`}
    >
      <div className="container">
        <div className="content">
          <p className="section-label">key features</p>
          <div className="grid grid-3">
            <div className="cell">
              <div className="graphic">
                <img src="assets/images/icon/nft.png" alt="" />
              </div>
              <p className="semi">
                The first large-scale{" "}
                <span className="highlight">interactive 3D NFT</span>{" "}
                collectible set
              </p>
              <p className="xs">
                6312 unique scifi-mythic characters ranging from sentient robots
                to immortal cosmic beings.{" "}
              </p>
            </div>
            <div className="cell">
              <div className="graphic">
                <img src="assets/images/icon/cubes-rotate.png" alt="" />
              </div>
              <p className="semi">
                View them from <span className="highlight">any angle</span>
              </p>
              <p className="xs">
                View them from any angle in an interactive stylized display -
                there’s nothing else like it on the Blockchain.
              </p>
            </div>

            <div className="cell">
              <div className="graphic">
                <img src="assets/images/icon/diorama.png" alt="" />
              </div>
              <p className="semi">
                Showcase your Voxos in a range of{" "}<span className="highlight">3D diorama environments</span>
              </p>
              <p className="xs">
                VoxoDeus is the first project where collectors can showcase 3D characters (Voxos) in 3D background scenes (3D dioramas).{" "}
              </p>
            </div>
          </div>
          <p>
            <br />
            <br />
            <br />
            <br />
          </p>
          {/* <p className="section-label">
            DESIGNED TO BOOST COMMISSION FOR MINTERS
          </p>
          <p>
            <br />
          </p> */}
          {/* <div className="grid grid-3">
            <div className="cell">
              <div className="graphic">
                <img src="assets/images/icon/royalty.png" alt="" />
              </div>
              <p className="semi">
                Minters will receive{" "}
                <span className="highlight">lifetime royalties</span> on all
                future subalterns
              </p>
              <p className="xs">
                All minters get to equally share 2% of the total OpenSea
                transaction volume for each NFT minted. Minters will share an
                additional 4% of transaction volume until L2 pools are activated
                later this year! You’ll be able to transfer royalty ownership
                later in the year (...or not 😉){" "}
              </p>
            </div>

            <div className="cell">
              <div className="graphic">
                <img src="assets/images/icon/prize.png" alt="" />
              </div>
              <p className="semi">
                A <span className="highlight">500ETH reward*</span> for the 1
                collector who discovers 8 hidden Omega Keys
              </p>
              <p className="xs">
                The Omega forged 8 keys. They periodically grant copies of them
                to characters in the Cypherverse. At launch, 16 characters will
                hold keys; gradually, more will be given. The first wallet to
                have owned 8 unique Omega Key characters (at any point) receives
                500ETH.{" "}
              </p>
            </div>
            <div className="cell">
              <div className="graphic">
                <img src="assets/images/icon/forest.png" alt="" />
              </div>
              <p className="semi">
                <span className="highlight">Everybody wins</span> and makes
                money
              </p>
              <p className="xs">
                As the community edges closer to the 500ETH prize by buying and
                selling Voxos, our NFT prices will rise. So, whether you're
                hodling for dear life or aping to hold the first wallet to have
                owned 8 keys, everybody benefits in the Cypherverse.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default GlanceSection;
