import styled from "styled-components";
import * as size from "assets/CSSPalette/sizes";
import * as color from "assets/CSSPalette/colors";
import media from "themes/media";

export const Modal = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #000;
    z-index: -1;
    position: absolute;
  }
  > div {
    position: absolute;
    padding: 15px;
    left: 25px;
    top: 20px;
    cursor: pointer;
    fill: ${color.VOID_3};
    transition: fill 0.15s ease-out;
    &:hover {
      fill: ${color.VOID_6};
    }
  }
  > iframe {
    width: 100%;
    height: 100%;
    border: none;
    body {
      margin: 0 !important;
    }
  }
`;
