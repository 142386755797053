import React, { useEffect, useRef } from 'react';
import gsap, { TimelineLite } from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import openSeaButton from 'assets/images/mint_now_button.png';
import { MINT_NFT_ADDRESS } from 'utils/constants';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);
const IntroSection = ({ sectionClass, setIntroSectionHeight }) => {
  var sectionElement = useRef();

  useEffect(() => {
    setIntroSectionHeight && setIntroSectionHeight(sectionElement?.current?.getBoundingClientRect()?.height);
    throttle(scrollThrottled());
  }, []);

  // Debounce functions
  function scrollThrottled() {
    if (window.innerWidth > 1200) {
      var tl = new TimelineLite({
        paused: true,
        scrollTrigger: {
          trigger: '#intro',
          start: 'top top',
          end: '+=300%',
          scrub: true,
          pin: true,
          // markers: true
          // pinSpacing: 'margin'
        },
      });

      tl.to('.intro .border, .intro .content', { x: '-60vw', opacity: 0 });
      tl.to('.parade .r1', { x: '-110%', duration: 2 }, '<');
      tl.to('.parade .r2', { x: '-123%', duration: 2 }, '<');
      tl.to('.parade .r3', { x: '-123%', duration: 2 }, '<');
      tl.to('.parade .r4', { x: '-121%', duration: 2 }, '<');
      tl.to('.parade .r5', { x: '-110%', duration: 2 }, '<');
      tl.to('.parade .r6', { x: '-110%', duration: 2 }, '<');
      tl.from('.right', { x: '100%', opacity: 0, duration: 1 }, '+1.5');
    }
  }

  // raf-throttle
  var throttle = callback => {
    let requestId = null;

    let lastArgs;

    const later = context => () => {
      requestId = null;
      callback.apply(context, lastArgs);
    };

    const throttled = function (...args) {
      lastArgs = args;
      if (requestId === null) {
        requestId = requestAnimationFrame(later(this));
      }
    };

    throttled.cancel = () => {
      cancelAnimationFrame(requestId);
      requestId = null;
    };

    return throttled;
  };
  return (
    <div>
      <section ref={sectionElement} id="intro" className={`intro ${sectionClass}`}>
        <div className="container" rx="6" ry="4">
          <div className="border">
            <svg width="48" height="540" viewBox="0 0 48 540" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.3">
                <path d="M37.5 528L10.5 501L10.5 9" stroke="white" filter="url(#f1)" />
              </g>
              <defs>
                <filter
                  id="f1"
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="5" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.548228 0 0 0 0 0.524451 0 0 0 0 1 0 0 0 0.5 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
          <div className="content">
            <p className="section-label move" rx="2" ry="2">
              <span>FROM THE CYPHERVERSE</span>
            </p>
            <h1 className="move" rx="2.4" ry="2.4">
              <span>
                The first LARGE-SCALE
                <br />
                Interactive 3D
                <br />
                NFT Collectibles
              </span>
            </h1>
            <p className="semi move" rx="3" ry="3">
              <span>
                Voxelated. Techno-utopic. Pretty good design.
                <br />
                <br />
                Welcome to the future of art collectibles, built block-by-block for the NFT community.
              </span>
            </p>
            <div className="count">
              <img src="assets/images/6312_limited_edition.png" alt="" />
            </div>
          </div>

          <div className="parade-wrapper">
            <div className="parade">
              <img className="r1" src="assets/images/carousel/1.png" alt="" />
              <img className="r2" src="assets/images/carousel/2.png" alt="" />
              <img className="r3" src="assets/images/carousel/3.png" alt="" />
              <img className="r4" src="assets/images/carousel/4.png" alt="" />
              <img className="r5" src="assets/images/carousel/5.png" alt="" />
              <img className="r6" src="assets/images/carousel/6.png" alt="" />
            </div>
          </div>
          <div className="right">
            <h3>voxodeus</h3>
            <div className="voxo-description">
              <p>6,312 unique, scifi-mythic creatures, ranging from sentient robots to immortal cosmic beings...</p>
              <p>
                3D interactivity, dynamic backgrounds, ambient music, and also the first NFT project to partner with{' '}
                <a href="https://twitter.com/TheSandboxGame" target="_blank">
                  <span className="highlight">@TheSandboxGame</span>
                </a>{' '}
                to create playable avatars for VoxoDeus NFT owners.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IntroSection;
