import React from 'react';
import { useHistory } from 'react-router';
import {
  AttributeLine,
  AttributeName,
  AttributesList,
  AttributesWrapper,
  AttributeTag,
  AttributeValue,
  SectionTitle,
  AttributeButton,
} from '../characterDetailsPage.styled';
import { RarityPoints } from '../RarityPoints';

export const Attributes = ({ title, keys, isButton, rarity, keyName }) => {
  const history = useHistory();

  const handleOnClick = filter => {
    const category =
      filter.name === 'FACTION' || filter.name === 'ARCHETYPE'
        ? `${filter.name.toLowerCase()}s`
        : filter.name.toLowerCase();

    const customLink = `/gallery?filters=sortBy:Ascending,${category}:${filter.value}`;
    history.push(customLink);
  };

  const ValueComponent = ({ children, value }) => {
    return isButton ? (
      <AttributeButton onClick={() => handleOnClick(value)}>{children}</AttributeButton>
    ) : (
      <AttributeValue>{children}</AttributeValue>
    );
  };
  return (
    <AttributesWrapper>
      <SectionTitle>{title}</SectionTitle>
      <AttributesList>
        {keys.map(key =>
          key.value ? (
            <AttributeLine key={key.name}>
              <AttributeName>{key.name}</AttributeName>
              <ValueComponent value={key}>{key.value}</ValueComponent>
              {key.keyName && <RarityPoints points={rarity.traits[key.keyName]} isFull={false} />}
            </AttributeLine>
          ) : (
            <AttributeTag key={key.name}>{key.name}</AttributeTag>
          ),
        )}
      </AttributesList>
    </AttributesWrapper>
  );
};
