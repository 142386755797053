import { useEffect, useState } from 'react';
import { useMetamask } from 'use-metamask';
import './App.scss';

import { NavBar } from './components/NavBar';
import React from 'react';
import { AppContextProvider } from 'context/AppContext';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import LandingPage from 'containers/LandingPage/LandingPage';
import GalleryPage from 'containers/GalleryPage';
import CharacterDetailsPage from 'containers/CharacterDetailsPage';

import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import ARViewer from 'containers/AR-Viewer';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

function getLibrary(provider, connector) {
  return new Web3Provider(provider);
}

function App() {
  const { active, account } = useWeb3React();
  const { connect, metaState } = useMetamask();

  const [mintRate, setMintRate] = useState(null);
  const [orderToMint, setOrderToMint] = useState(null);
  const [seaportObject, setSeaportObject] = useState(null);
  const [newTxn, setNewTxn] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const gtmParams = { id: 'GTM-NQRLCBV' };

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  return (
    <>
      <Router>
        <GTMProvider state={gtmParams}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <AppContextProvider
              value={{
                metaState,
                mintRate,
                seaportObject,
                setSeaportObject,
                orderToMint,
                isMobile,
              }}
            >
              <NavBar isConnected={active} />
              {
                <div id='main-wrapper'>
                  <Switch>
                    <Route exact path='/'>
                      <LandingPage />
                    </Route>
                    <Route exact path='/gallery'>
                      <GalleryPage />
                    </Route>
                    <Route exact path='/voxo/:voxoId'>
                      <CharacterDetailsPage />
                    </Route>
                    <Route exact path='/ar/:voxoId/:codeName'>
                      <ARViewer />
                    </Route>
                    <Route exact path='/okg'>
                      <Redirect to='/' />
                    </Route>
                  </Switch>
                </div>
              }
            </AppContextProvider>
          </Web3ReactProvider>
        </GTMProvider>
      </Router>
    </>
  );
}

export default App;
