import React, { useEffect, useState, useRef } from 'react';
import { members } from './members';

const TeamSection = () => {
  const [toggle, setToggle] = useState(false);
  const [index, setIndex] = useState(0);
  const [offset, setOffset] = useState(0);

  const box = useRef();

  useEffect(() => {
    if (toggle) {
      document.body.classList.add('overlay-open');
    } else {
      document.body.classList.remove('overlay-open');
    }
  }, [toggle]);
  const toggleModal = () => {
    setToggle(prev => !prev);
  };

  useEffect(() => {
    var padding = window.innerWidth > 540 ? 90 : 40;
    setOffset(index * (box.current.clientWidth + padding));
  }, [index]);
  const nextCard = () => {
    if (index < members.length - 1) {
      setIndex(prev => prev + 1);
    } else {
      setIndex(0);
    }
  };

  const previousCard = () => {
    if (index > 0) {
      setIndex(prev => prev - 1);
    } else {
      setIndex(members.length - 1);
    }
  };

  const profileBaseUrl = '/assets/images/team'

  return (
    <>
      <section className="team" id="team">
        <div className="container">
          <h2>TEAM & CONTRIBUTORS</h2>
          <div className="team-list">
            {members.map((member, i) => (
              <div
                className="team-member "
                data-id={member.id}
                key={member.id}
                onClick={() => {
                  toggleModal();
                  setIndex(i);
                }}
              >
                <div className="portrait">
                  <img src={`${profileBaseUrl}/${member.voxo_token_id}.jpg`} alt={member.name} />
                </div>
                <div className="desc">
                  <div className="name">{member.name}</div>
                  <div className="title">{member.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="team-overlay">
        <a className="close" onClick={toggleModal} style={{ zIndex: '999' }}>
          <img src={"assets/images/btns/close.svg"} alt="Close" />
        </a>
        <div className="cards-viewport">
          <div className="cards" style={{ transform: 'translateX(-' + offset + 'px)' }}>
            {members.map((card, i) => (
              <div ref={box} className={`card ${i === index ? 'active' : null}`} id={card.id} key={card.id}>
                <div className="top">
                  <div className="id">
                    <div className="name">
                      {card.name}
                    </div>
                    <div className="title">{card.title}</div>
                  </div>
                  <div className="portrait">
                    <img src={`${profileBaseUrl}/${card.voxo_token_id}.jpg`} alt={card.name} />
                  </div>
                </div>
                <div className="bio">{card.bio}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="cardnav">
          <a className={`prev ${index === 0 ? 'disabled' : null}`} data-dir="prev">
            <img src={"assets/images/btns/prev.svg"} alt="previous" onClick={previousCard} />
          </a>
          <a className={`next ${index === members.length - 1 ? 'disabled' : null}`} data-dir="next">
            <img src={"assets/images/btns/next.svg"} alt="next" onClick={nextCard} />
          </a>
        </div>
      </div>
    </>
  );
};

export default TeamSection;
