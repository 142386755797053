import React, { useMemo } from 'react';

import LogoFooter from 'assets/icons/voxodeus_logo_-_tp_stacked.png';
import TwitterIcon from 'assets/images/icon/tw.svg';
import DgIcon from 'assets/images/icon/dg.svg';
import GitIcon from 'assets/images/icon/github.svg';
import { ReactComponent as OpenSeaIcon } from 'assets/icons/Logomark-Opensea.svg';
import openSeaButton from 'assets/images/opensea_button_white.png';
import { OpenseaButton } from 'components/NavBar/styles.js';

const Footer = ({ bgLess = false, black }) => {
  const classNames = useMemo(() => {
    if (bgLess) {
      return 'bg-less';
    }
    if (black) {
      return 'black';
    }
    return '';
  }, [bgLess, black]);
  return (
    <footer className={classNames}>
      <div className='container'>
        <div className='top'>
          <div className='right'>
            <a href='/' className='logo'>
              <img src={LogoFooter} alt='VoxoDeus' />
            </a>
          </div>
          <div className='right'>
            <div className='chunk'>
              <a
                href='https://github.com/voxodeus/voxodeus-contracts'
                target='_blank'
                rel='noreferrer noopener nofollow'
                className='listed github'
              >
                <img src={GitIcon} alt='GitHub' />
              </a>
            </div>
            <div className='social chunk'>
              <a href='https://discord.gg/SxfTThwsX6' target='_blank' rel='noreferrer noopener nofollow'>
                <img src={DgIcon} alt='Discord' />
              </a>
              <a href='https://twitter.com/voxodeus' target='_blank' rel='noreferrer noopener nofollow'>
                <img src={TwitterIcon} alt='Twitter' />
              </a>
            </div>
            <div className='chunk'>
              <OpenseaButton>
                <a href={`https://opensea.io/collection/voxodeus`} target='_blank' rel='noreferrer'>
                  <OpenSeaIcon />{' '}
                  <div>
                    <span>View on</span>OpenSea
                  </div>
                </a>
              </OpenseaButton>
            </div>
          </div>
        </div>
        <div className='bottom'>
          <div className='cr'>All Rights Reserved. VoxoDeus 2023.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
