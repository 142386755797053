import React, { useMemo } from 'react';
import '@google/model-viewer';
import { NavLink, useParams } from 'react-router-dom';
import { ReactComponent as CLOSE_ICON_ICON } from 'assets/icons/close-button-icon.svg';
import {
  ArViewerModal,
  CloseModalButton,
  Controls,
  Header,
  HorizontalLine,
  Overlay,
  QRCodeSection,
  QRCodeWrapper,
  QRHeader,
  QRSubHeader,
  ShowARButton,
} from './arviewer.styled';
import { padLeadingZeros } from 'utils/numberFormat';
import { ReactComponent as ViewInARIcon } from 'assets/icons/view_in _AR_icon.svg';
import { ReactComponent as BackIcon } from 'assets/icons/chevron_left.svg';
import QRCode from 'react-qr-code';
import * as color from 'assets/CSSPalette/colors';
import { ARWEAVE } from '../../utils/constants';

const ARViewer = () => {
  const { voxoId, codeName } = useParams();
  const voxoIdLeadingZeros = useMemo(() => padLeadingZeros(voxoId, 4), [voxoId]);
  return (
    <Overlay>
      <NavLink to={`/voxo/${voxoId}`}>
        <CloseModalButton>
          <CLOSE_ICON_ICON />×
        </CloseModalButton>
      </NavLink>
      <Controls>
        <div>
          <BackIcon />
          <a href={`/voxo/${voxoId}`}>Back to character </a>
        </div>
        <div>
          <HorizontalLine />
          VOXO #{voxoIdLeadingZeros}
        </div>
      </Controls>
      <Header>AR viewer</Header>
      <ArViewerModal>
        <model-viewer
          class='model-viewer'
          src={`${ARWEAVE.HERO_MODELS}/${codeName}.glb`}
          poster={`${ARWEAVE.AR_PREVIEW}/${voxoId}.png`}
          alt={`VoxoDeus #${voxoIdLeadingZeros} 3D Model`}
          shadow-intensity='1'
          camera-controls
          auto-rotate
          ar
          scale='0.025 0.025 0.025'
          // ar-modes="webxr scene-viewer quick-look"
          ar-modes='quick-look scene-viewer webxr'
          camera-orbit='30deg 75deg 150%'
          max-field-of-view='80deg'
        >
          <ShowARButton slot='ar-button'>
              <span>
                <ViewInARIcon />
              </span>{' '}
            Click to launch AR
          </ShowARButton>
        </model-viewer>
        <section className='attribution'>
          <h1>voxodeus #{voxoIdLeadingZeros}</h1>
          <span>
              By{' '}
            <a href='https://voxodeus.io/' target='_blank' rel='noreferrer'>
                THE CYPHERVERSE
              </a>
            </span>
        </section>
        <QRCodeSection>
          <QRHeader>VIEW ON MOBILE</QRHeader>
          <QRSubHeader>Scan QR code</QRSubHeader>
          <QRCodeWrapper>
            <QRCode size={65} value={window.location.href} fgColor={color.TRON_2} />
          </QRCodeWrapper>
        </QRCodeSection>
      </ArViewerModal>
    </Overlay>
  );
};
export default ARViewer;
