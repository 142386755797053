import axios from 'axios';
import { ARWEAVE } from '../utils/constants';

const getVoxosForAddress = async userId => {
  return await axios.get(
    // `http://localhost:8000/api/collections/voxodeus/user/${userId}`
    `https://api.cypherverse.io/api/collections/voxodeus/user/${userId}`);
};

const getVoxosWithRanking = async (ids, offset) => {
  return await axios.get(
    `https://api.cypherverse.io/api/collections/voxodeus/search?summary=true&incl_unminted=false&q={"token_id" : [${ids}]}&offset=${offset}&limit=40&sort=token_id&direction=asc`,
  );
};

const getVoxoById = async (id) => {
  return await axios.get(
      `${ARWEAVE.METADATA}/${parseInt(id.replace('#', ''), 10)}.json`,
  );
};

export { getVoxosForAddress, getVoxosWithRanking, getVoxoById };
