import React, { useEffect, useState, useCallback } from 'react';
import IntroSection from '../sections/intro-section';
import ViewerSection from '../sections/viewer-section';
import GlanceSection from '../sections/glance-section';
import CypherverseSection from '../sections/cypherverse-section';
import CharactersSection from '../sections/characters-section';
import HowSection from '../sections/how-section';
import TeamSection from '../sections/team-section';
import Footer from '../components/footer';

import discordVoxos from 'assets/images/discord_join_voxos.png';
import discordIcon from 'assets/icons/discord-blue.svg';
import { Discord, DiscordImages, DiscordSection, DiscordSectionText } from './homepage.styled';

const HomePage = ({ sectionsClass, setSectionsClass }) => {
  useEffect(() => {
    let timer1 = setTimeout(() => addClassBySection(1, ' play '), 500);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  /*************************** SCROLL ******************************/
  const [y, setY] = useState(window.scrollY);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [introSectionHeight, setIntroSectionHeight] = useState(0);

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;

      var sTop = window.scrollY;
      if (sTop < introSectionHeight) {
        // removeClass("scrolled") in the header
        removeClassBySection(0, 'scrolled ');
      } else {
        // addClass("scrolled") in the header
        addClassBySection(0, 'scrolled ');
      }
      if (getLastScrollDirection()) {
        addClassBySection(0, 'shrink ');
      } else {
        removeClassBySection(0, 'shrink ');
      }

      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  // addClass to the section
  const addClassBySection = (sectionId, newClassName) => {
    let sectionClassTmp = sectionsClass[sectionId];
    if (!sectionClassTmp.includes(newClassName)) {
      sectionClassTmp += newClassName;
      updateSectionsClass(sectionId, sectionClassTmp);
    }
  };

  // removeClass from the section
  const removeClassBySection = (sectionId, classNameToRemove) => {
    let sectionClassTmp = sectionsClass[sectionId];
    sectionClassTmp = sectionClassTmp.replace(classNameToRemove, '');
    updateSectionsClass(sectionId, sectionClassTmp);
  };

  // Update the sectionsClass state
  const updateSectionsClass = (sectionId, newClassName) => {
    setSectionsClass(prev => {
      let sectionsClassTmp = [...prev];
      sectionsClassTmp[sectionId] = newClassName;
      return sectionsClassTmp;
    });
  };

  function getLastScrollDirection() {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    var ret = st > lastScrollTop;
    setLastScrollTop(st);
    return ret;
  }
  return (
    <>
      <div className="bg">
        {/*<img src="assets/images/bg2.png" alt="" id="bg0"/> */}
        <div className="grad">
          <div id="bg1"></div>
          <div id="bg2"></div>
          <div id="bg3"></div>
        </div>
      </div>
      <IntroSection sectionClass={sectionsClass[1]} setIntroSectionHeight={setIntroSectionHeight} />
      <ViewerSection addClassBySection={addClassBySection} sectionClass={sectionsClass[2]} />
      <GlanceSection addClassBySection={addClassBySection} sectionClass={sectionsClass[3]} />

      <CypherverseSection addClassBySection={addClassBySection} sectionClass={sectionsClass[5]} />
      <CharactersSection mount={sectionsClass[5].includes('play')} />
      <HowSection sectionClass={sectionsClass[7]} />
      <TeamSection sectionClass={sectionsClass[8]} />
      <section>
        <DiscordSection href="https://discord.gg/voxodeus" target="_blank" sectionClass={sectionsClass[9]}>
          <DiscordImages>
            <img src={discordVoxos} alt="" />
          </DiscordImages>
          <DiscordSectionText>
            <span>Join the community on discord</span>
            <div>
              <img src={discordIcon} alt="" />
              <Discord>discord.gg/voxodeus</Discord>
            </div>
          </DiscordSectionText>
        </DiscordSection>
      </section>
      <Footer sectionClass={sectionsClass[10]} />
    </>
  );
};

export default HomePage;
