const VoxColors = {
  dark: '#0B1528',
  white: '#FFFFFF',
  void1: '#0F1741',
  voidNeon: '#8C86FF',
  candyNeon: '#FF6A84',
  candy5: '#E17790',
  candy6: '#F1AEBB',
  tron1: '#0e1c34',
  tron2: '#2a406026',
  tron3: '#43587a',
  tron4: '#5D7195',
  tron4Light: '#5d71951a',
  tron6: '#93A7CD',
  tron5: '#778BB0',
  tron7: '#B3C3E2',
  tron8: '#ccd9f2',
  tron8Light: '#ccd9f21a',
  tronNeon: '#5793FF',
  blue5: '#71BEFE',
  blue5Light: '#71befe0d',
  matrixNeon: '#56F6CE',
  orangeNeon: '#FF896B',
};

export { VoxColors };
