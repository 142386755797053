import React, { useState } from 'react';
import { archetypes } from './archetypesArray';

const Archetypes = ({ isActive }) => {
  const [activeArchetype, setActiveArchetype] = useState('sapiens');
  const changeActiveArchetype = id => {
    setActiveArchetype(null);
    setTimeout(() => {
      setActiveArchetype(id);
    }, 400);
  };
  return (
    <div className={`tab-content ${isActive ? 'active' : null}`} id="archetypes">
      <div className="switch">
        {Object.keys(archetypes).map(item => (
          <a
            key={item}
            style={{ cursor: 'pointer' }}
            onClick={() => changeActiveArchetype(item)}
            className={`archetype-tab ${item === activeArchetype ? 'active' : null}`}
          >
            {item}
          </a>
        ))}
      </div>
      {Object.keys(archetypes).map((archetype, i) => {
        return (
          <div className={`inner ${activeArchetype === archetype ? 'active' : null}`} id={archetype} key={archetype}>
            <div className="archetypes-grid">
              {archetypes[archetype].map(item => {
                return (
                  <div className="archetype" key={item.name}>
                    <img className="archetype-image" src={item.img} />
                    <div className="archetype-text">
                      <p className="archetype-header">{item.name}</p>
                      <div className="archetype-fraction">
                        {item.fraction}
                        {item.prof ? <span> | {item.prof}</span> : null}
                      </div>
                      <div className="archetype-legend">
                        {Array.isArray(item.legend)
                          ? item.legend.map((el, index) =>
                              el.text === '' ? (
                                <p key={index}></p>
                              ) : (
                                <div key={index} className={el.style}>
                                  {el.text}
                                </div>
                              ),
                            )
                          : item.legend}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Archetypes;
