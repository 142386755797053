export const members = [
  {
    id: 'soti',
    name: 'Soti',
    title: 'Project Lead',
    bio: 'Soti is a Product Designer with 10 yrs experience, he’s worked on everything from carbon fiber drones - sex toys - surgical robots. He’s laser focused on making sure users actually get what they want whilst simultaneously working across multiple verticals. He’s taking all of this experience, combined with a love of crypto since ‘16, and channeling it into the virtual world. Fun fact designers have a higher chance of being dyslexic, if he’s written something and it’s misspelt you can be sure it’s really coming from him.',
    voxo_token_id: 4173,
  },
  {
    id: 'zen',
    name: 'Zen',
    title: 'Creative Director',
    bio: "Zen is a game designer who leads a small studio of devs working from the UK, Australia, and Hong Kong. Before getting into games in 2017, he worked as a copywriter and art director. With a borderline-irrational appreciation for neon nighttime hues, Zen's here to ensure that our voxel art looks and feels like something out of a cyberpunk feverdream. He picked up 'Zen' as a 13-year old - a hurried typo when he finally made the Street Fighter II high score screen in his local arcade.",
    voxo_token_id: 2627,
  },
  {
    id: 'sierra',
    name: 'Sierra',
    title: 'Operations & Design',
    bio: "Sierra has led digital design projects for some of the world's biggest design and advertising agencies as well as local startups and non-profits alike. Her design projects run the gamut - mobile apps, crowdsourced food rescue sites, motion sensor experiences, video games, fintech dashboards, touch screen kiosks.. even ATMs. Most suited to situations involving any combination of cats, plants, scifi, games, and music.",
    voxo_token_id: 4962,
  },
  {
    id: 'voxofox',
    name: 'voxofox',
    title: 'Tech 🌈 Unicorn',
    bio: 'Fox is a technologist with 10 years of experience building web and data products. He is a linguist who fell to the dark side of numbers, plays three-dimensional chess with the other forest creatures, and marks his relationship status with spreadsheets as “complicated”.',
    voxo_token_id: 4923,
  },
  {
    id: 'nfsteve',
    name: 'NFSteve',
    title: 'Community & Partnerships',
    bio: "NFSteve joined the NFT space in early 2021 and has never looked back. Previously holding many different roles in large CPG companies, he has taken his passion for people and teamwork to the Cypherverse. He is excited to bring new ideas and partnerships to VoxoDeus in order to grow this project to new levels. When he's not deep into the metaverse, he loves home-brewing beer, playing dungeons & dragons, and hanging out with his dog, Jake!",
    voxo_token_id: 4214,
  },
  {
    id: 'pierre',
    name: 'Pierre',
    title: 'Art Director',
    bio: "I am a French baguette. I've enjoyed creating and painting since my childhood. A little solitary, I've always loved playing/working late in the night. I'm a design school grad, skill juggler (drawing, 3D, branding), and have a crisp crust ... During my free time I enjoy travelling, exploring and feeling lost somewhere... anywhere! I'm also keen on surfing, but the Hong Kong waves still need to grow up a little to enjoy that here =)",
    voxo_token_id: 5957,
  },
  {
    id: 'wolf',
    name: 'autumn wolf',
    title: 'Creative Writing & Music',
    bio: 'Alex is 13+ years deep in a career in the creative world, with experience ranging from live music touring to film script writing and a bit of everything in between. Currently he divides his time between being the audio director and story/script guy for an indiegame studio, and the Lore Keeper-slash-audio generator for VoxoDeus. His main responsibility in the Cypherverse is to compliment the amazing aesthetics with a story that will take the community on a journey in search of Omega, and enable Voxers and Lore Speakers alike to contribute creatively to this ever-expanding universe.',
    voxo_token_id: 4991,
  },
  {
    id: 'patak17',
    name: 'Patak17',
    title: 'Frontend Dev',
    bio: 'Patak17 is a strange duck that is committed to excellence! His favorite thing is to manipulate data in JavaScript, and show it as confusingly as possible to end user. On occasion, screens that he makes are very pleasant to the eye. In off time, he plays video games and likes to visit the gym.',
    voxo_token_id: 5566,
  },
  {
    id: 'alfredo',
    name: 'alfredo',
    title: 'Blockchain Dev',
    bio: 'Alfredo is a Senior Blockchain Developer, with deep experience in implementing new technological trends for the development of solutions that require Big Data, IoT, Mobile First Web App, BlockChain, AI, and CyberSecurity. He seeks out new things that change the world, and is dedicated to constant mental agility and creativity. In his free time he enjoys the NBA, Marvel, Dragon Ball, and Anime in general.',
    voxo_token_id: 5946,
  },
  {
    id: 'akshay',
    name: 'Akshay',
    title: 'Tech Counsel',
    bio: 'Akshay has 6+ years of experience in the blockchain industry. He works for the leading data analytics firm for the Ethereum Blockchain. His favourite Voxo is The Omega, the first enigmatic being who programmed the CypherVerse. He also DJs in his free time.',
    voxo_token_id: 4961,
  },
  {
    id: 'giddi',
    name: 'giddi',
    title: '3D Modeling',
    bio: "I'm an architect from the Philippines with a background in 3D modelling and designed environments until I discovered voxel art in 2017 and have been in love ever since. It's been quite a journey but I believe voxels are the future.",
    voxo_token_id: 4981,
  },

  {
    id: 'ding',
    name: 'Ding',
    title: 'Pipeline Engineer',
    bio: 'My name is Ding and I am a creative technologist and educator. I started my professional career with producing animation for photographers, game makers and writers, and soon found a passion in education. Most recently, I brought FBP (Flow Based Programming) to web technologies with FlowSandbox.io, showing hundreds of aspiring students an accessible path to tech literacy.',
    voxo_token_id: 4339,
  },
  {
    id: 'aleksei',
    name: 'Aleksei',
    title: 'Frontend Dev',
    bio: 'As a multidisciplinary frontend developer, Aleksei passionate about creating beautiful, functional websites and applications, through motion, typography, vector graphics and creative coding for companies around the world. He spends his uptime building analytics dashboards for crypto companies and downtime boxing, doing film photography, and playing with his baby.',
    voxo_token_id: 5259,
  },
  {
    id: 'derschmale',
    name: 'Der Schmale',
    title: 'Graphics Programmer',
    bio: 'David is a freelance 3D graphics programmer based in Ghent, Belgium. With a lifelong passion for anything real-time, he started his career as a frontend developer using Flash where he contributed to various open source graphics libraries, most notably as a core dev for the 3D engine Away3D. Having gone completely grey after years coding in ActionScript, he moved on to other tech and now channels his love for lighting and post-processing to make sure every single voxel gets the most out of the GPU.',
    voxo_token_id: 5951,
  },
  {
    id: 'leah',
    name: 'Leah',
    title: 'Legal Counsel',
    bio: 'Leah is a US financial regulations lawyer who specialises in investigations, litigations, and dispute resolution.',
    voxo_token_id: 5446,
  },
];
