import React from "react";
import { ReactComponent as LoadingIcon } from "assets/icons/tail-spin.svg";
import { LoadingWrapper } from "./characterDetailsPage.styled";

export const LoadingDetailsState = () => {
  return (
    <div>
      <LoadingWrapper>
        <LoadingIcon />
        Loading...
      </LoadingWrapper>
    </div>
  );
};
