import React, { useState } from 'react';
import Archetypes from './Archetypes/Archetypes';
import openSeaButton from 'assets/images/mint_now_button.png';
import { MINT_NFT_ADDRESS } from 'utils/constants';
import { tabs, species, speciesMenu, factions, factionsMenu } from './voxos';
import CharacterModel from './CharacterModel';

const CharactersSection = ({ mount }) => {
  const [activeSpecie, setActiveSpecie] = useState('sapiens');
  const [activeFaction, setActivFaction] = useState('angos');
  const [activeTab, setActiveTab] = useState('species');

  // 3d
  const [charIndex, setCharIndex] = useState(0);
  const [charFactionIndex, setCharFactionIndex] = useState(0);

  const changeSpecie = id => {
    setCharIndex(0);

    setActiveSpecie(null);
    setTimeout(() => {
      setActiveSpecie(id);
    }, 400);
  };

  const changeFaction = id => {
    setCharFactionIndex(0);
    setActivFaction(id);

    setActivFaction(null);
    setTimeout(() => {
      setActivFaction(id);
    }, 400);
  };

  return (
    mount && (
      <section id="characters" className="chars">
        <div className="container">
          <div className="tabs-wrapper">
            <div className="tabs-viewport">
              <div className="tabs">
                {tabs.map(tab => (
                  <a
                    key={tab.name}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setActiveTab(null);
                      setTimeout(() => {
                        setActiveTab(tab.name);
                      }, 400);
                    }}
                    className={tab.name === activeTab ? 'active' : null}
                  >
                    {tab.title}
                  </a>
                ))}
              </div>
            </div>
            <div className="tabs-container">
              <div className={`tab-content ${'species' === activeTab ? 'active' : null}`} id="species">
                <div className="switch">
                  {speciesMenu.map(item => (
                    <a
                      key={item.name}
                      style={{ cursor: 'pointer' }}
                      onClick={() => changeSpecie(item.name)}
                      className={item.name === activeSpecie ? 'active' : null}
                    >
                      {item.title}
                    </a>
                  ))}
                </div>
                {species.map(specie => (
                  <div
                    className={`inner ${activeSpecie === specie.id ? 'active' : null}`}
                    id={specie.id}
                    key={specie.id}
                  >
                    <div className="desc">
                      <h3 className="title">{specie.title}</h3>
                      <div className="sn">
                        (<span>{specie.count}</span>/{specie.counter})
                      </div>
                      {specie.descriptions?.map((description, i) => (
                        <p key={i+150}>{description}</p>
                      ))}
                      <h3 className="features">FEATURES</h3>
                      <ul>
                        {specie.features?.map((feature, i) => (
                          <li key={i}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="viewer">
                      {specie.statsss?.length > 1 && (
                        <div className="sibnav">
                          <a
                            onClick={() => {
                              if (charIndex > 0) {
                                setCharIndex(prev => prev - 1);
                              }
                            }}
                            className={`prev ${charIndex < 1 && 'disabled'}`}
                          >
                            <img src="assets/images/btns/prev.svg" alt="previous" />
                          </a>
                          <a
                            onClick={() => {
                              if (charIndex < specie.statsss.length - 1) {
                                setCharIndex(prev => prev + 1);
                              }
                            }}
                            className={`next ${charIndex === specie.statsss.length - 1 && 'disabled'}`}
                          >
                            <img src="assets/images/btns/next.svg" alt="next" />
                          </a>
                        </div>
                      )}
                      {specie.statsss?.map((char, i) => {
                        if (i === charIndex && specie.id === activeSpecie) {
                          return <CharacterModel key={i+10000} voxoId={char} />;
                        }
                      })}
                    </div>
                  </div>
                ))}
              </div>
              <div className={`tab-content ${'factions' === activeTab ? 'active' : null}`} id="factions">
                <div className="switch">
                  {factionsMenu.map(item => (
                    <a
                      key={item.name}
                      style={{ cursor: 'pointer' }}
                      onClick={() => changeFaction(item.name)}
                      className={item.name === activeFaction ? `active ${item.name}` : item.name}
                    >
                      {item.title}
                    </a>
                  ))}
                </div>
                {factions.map(faction => (
                  <div
                    className={`inner ${activeFaction === faction.id ? `active` : null}`}
                    id={faction.id}
                    key={faction.id}
                  >
                    <div className="desc">
                      <h3 className={`title ${faction.id}`}>
                        {faction.title} ({faction.id})
                      </h3>
                      <div className="sn">
                        (<span>{faction.percent}</span>)
                      </div>
                      <div className="subtitle">{faction.subtitle}</div>
                      {faction.description.split('**').map((p, z) => (
                        <p key={z+1000} className={`factions-description ${faction.id}`}>{p}</p>
                      ))}
                      <div className="characteristics">
                        <div>
                          <div className="head">KEYWORDS</div>
                          <ul>
                            {faction.keywords.map((feature, i) => (
                              <li key={i} className={faction.id}>
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <div className="head">position in the world</div>
                          <ul>
                            {faction.position.map((feature, i) => (
                              <li key={i} className={faction.id}>
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="viewer">
                      <img src={faction.image} alt="" />
                    </div>
                  </div>
                ))}{' '}
              </div>
              <Archetypes isActive={'archetypes' === activeTab} />
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default CharactersSection;
