import React, { memo, useEffect, useRef, useState } from "react";
import "App.scss";
import HomePage from "pages/HomePage";

function Star(x, y, offset, duration = 100, size = 2) {
  //constructor
  this.x = x;
  this.y = y;
  this.duration = duration;
  this.offset = offset;
  this.size = size;
  this.timer = offset % duration;

  //functions
  this.draw = function (canvas) {
    //Calculate animations
    if (this.timer > this.duration) {
      this.timer = 0;
    }
    this.timer += 1;

    //Calculate
    var framesize =
      Math.abs(this.timer / this.duration - 0.5) * this.size + this.size / 10;

    //Update element
    canvas.beginPath();
    canvas.arc(this.x, this.y, framesize, 0, Math.PI * 2, false);
    canvas.fillStyle = "white";
    canvas.fill();
  };
}
const LandingPage = () => {
  var canvasElement = useRef();
  var quantity = 500;
  var canvas = null;
  var stars = [];

  useEffect(() => {
    canvas = canvasElement?.current?.getContext("2d");
    for (let i = 0; i < quantity; i++) {
      var positionX = window.innerWidth * Math.random();
      var positionY = window.innerHeight * Math.random();
      var offset = Math.random() * 100;
      var duration = Math.random() * 20 + 10;
      var size = Math.random() * 2;
      stars.push(new Star(positionX, positionY, offset, duration, size));
    }
  }, []);

  setInterval(
    () => {
      if (canvas) {
        canvas.clearRect(0, 0, window.innerWidth, window.innerHeight);
        //Call all stars to update their animationx
        for (let i = 0; i < quantity; i++) {
          stars[i].draw(canvas);
        }
      }
    },

    100
  );

  const [sectionsClass, setSectionsClass] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  return (
    <>
      <canvas
        width={window.innerWidth}
        height={window.innerHeight}
        ref={canvasElement}
        id="stars"
      ></canvas>
      <HomePage
        sectionsClass={sectionsClass}
        setSectionsClass={setSectionsClass}
      />
    </>
  );
};

export default memo(LandingPage);
