import React, { useMemo } from 'react';
import {
  AttributesList,
  AttributesWrapper,
  GearItem,
  GearItemWrapper,
  SectionTitle,
} from '../characterDetailsPage.styled';
import { RarityPoints } from '../RarityPoints';

export const Gear = ({ voxoData }) => {
  const keys = useMemo(
    () =>
      Object.keys(voxoData.traits)
        .filter(key => key.includes('Gear | '))
        .reduce(
          (out, cur) => [
            ...out,
            {
              name: cur.replace('Gear | ', ''),
              value: voxoData.traits[cur],
              rarityPoints: voxoData.rarity.traits[cur],
            },
          ],
          [],
        ),
    [voxoData],
  );
  return (
    <AttributesWrapper>
      <SectionTitle>Gear</SectionTitle>
      <AttributesList>
        {keys.map(key => (
          <GearItemWrapper key={key.name}>
            <GearItem>
              {key.name}
              <span>| {key.value}</span>
            </GearItem>
            <RarityPoints points={key.rarityPoints} isFull={false} />
          </GearItemWrapper>
        ))}
      </AttributesList>
    </AttributesWrapper>
  );
};
