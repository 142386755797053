import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { fullList } from './viewer_list';
import {getVoxoById} from "../../services/voxosService";
import {ARWEAVE} from "../../utils/constants";

const ViewerSection = ({ addClassBySection, sectionClass }) => {
  var sectionElement = useRef();
  const [y, setY] = useState(window.scrollY);
  const [viewerOn, setViewerOn] = useState(false);
  const [list, setList] = useState([]);
  const [listItem, setListItem] = useState(null);
  const iframe = useRef();
  const [charIndex, setCharIndex] = useState(0);

  // Play animation when section is being shown
  const handleScroll = useCallback(() => {
    var offset = sectionElement?.current?.getBoundingClientRect()?.top - window.innerHeight / 2;
    if (offset < 0) addClassBySection(2, 'play ');
    setY(window.scrollY);
  }, [y]);

  // Scroll listener
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  useEffect(() => {
    fetchVoxo();
  }, [list, charIndex]);

  useEffect(() => {
    setList(shuffle(fullList));
  }, []);
  const handleFullScreen = () => {
    if (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    ) {
      if (iframe.current.requestFullscreen) {
        iframe.current.requestFullscreen();
      } else if (iframe.current.webkitRequestFullscreen) {
        iframe.current.webkitRequestFullscreen();
      } else if (iframe.current.mozRequestFullScreen) {
        iframe.current.mozRequestFullScreen();
      } else if (iframe.current.msRequestFullscreen) {
        iframe.current.msRequestFullscreen();
      }
    }
  };

  const fetchVoxo = () => {
    if (list.length === 0) return;
    getVoxoById(list[charIndex]).then((res) => {
      setListItem(res.data);
    });
  };

  const returnValueForTraitType = (type) => {
    if (listItem) {
      const attribute = listItem.attributes.find((x) => x.trait_type === type);
      return attribute ? attribute.value : '';
    }
  };

  return (
    <section ref={sectionElement} id='viewer' className={`viewer ${sectionClass}`}>
      {listItem ? (
          <>
            <iframe
                ref={iframe}
                key={charIndex}
                className={viewerOn && 'on'}
                data-faction={returnValueForTraitType("#3 Faction")}
                data-rarity={returnValueForTraitType("#2 Rarity")}
                data-archetype={returnValueForTraitType("#4 Archetype")}
                src={listItem.animation_url}
            ></iframe>
            <div className='stats with-banner'>
              <div className='row'>
                <span className='key'>ID</span>
                <a className='value btn-filter' href={`/voxo/${listItem.token_id}`}>
                  #{listItem.token_id.toString().padStart(4, '0')}
                </a>
              </div>
              <div className='row'>
                <span className='key'>SPECIES</span>
                <a className='value btn-filter' href={`/voxo/${listItem.token_id}`}>
                  {returnValueForTraitType("#1 Species")}
                </a>
              </div>
              <div className='row'>
                <span className='key'>RARITY</span>
                <a className='value btn-filter' href={`/voxo/${listItem.token_id}`}>
                  {returnValueForTraitType("#2 Rarity")}
                </a>
              </div>
              <div className='row'>
                <span className='key'>FACTION</span>
                <a className='value btn-filter' href={`/voxo/${listItem.token_id}`}>
                  {returnValueForTraitType("#3 Faction")}
                </a>
              </div>
              <div className='row'>
                <span className='key'>ARCHETYPE</span>
                <a className='value btn-filter' href={`/voxo/${listItem.token_id}`}>
                  {returnValueForTraitType("#4 Archetype")}
                </a>
              </div>
            </div>
          </>
      ) : null}
      <div className='sibnav with-banner'>
        <a
          onClick={() => {
            if (charIndex > 0) {
              setCharIndex(prev => prev - 1);
            }
          }}
          className={`prev ${charIndex < 1 && 'disabled'}`}
        >
          <img src={'assets/images/btns/prev.svg'} alt='previous' />
        </a>
        <a
          onClick={() => {
            if (charIndex < fullList.length - 1) {
              setCharIndex(prev => prev + 1);
            }
          }}
          className={`next ${charIndex === fullList.length - 1 && 'disabled'}`}
        >
          <img src={'assets/images/btns/next.svg'} alt='next' />
        </a>
      </div>
      {!viewerOn ? (
        <a onClick={() => setViewerOn(true)} className={`togglebig with-banner`}>
          <img src={'assets/images/btns/3d-rotation.svg'} alt='' />
        </a>
      ) : (
        <a onClick={() => setViewerOn(prev => !prev)} className={`toggleinteract`}>
          <img src={'assets/images/btns/pause.svg'} alt='' className='p' />
          <img src={'assets/images/btns/interact-s.svg'} alt='' className='i' />
        </a>
      )}

      <a onClick={handleFullScreen} className='togglefs' title='Toggle full screen'>
        <img src={'assets/images/btns/fullscreen.svg'} alt='' />
      </a>
    </section>
  );
};

export default memo(ViewerSection);
