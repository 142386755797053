export const TRON_1 = '#0E1C34';
export const TRON_2 = '#2A4060';
export const TRON_3 = '#43587A';
export const TRON_4 = '#5D7195';
export const TRON_5 = '#778BB0';
export const TRON_6 = '#93A7CD';
export const TRON_7 = '#B3C3E2';
export const TRON_8 = '#CCD9F2';

export const VOID_1 = '#0F1741';
export const VOID_2 = '#292A5F';
export const VOID_3 = '#42427C';
export const VOID_4 = '#5E5B9C';
export const VOID_5 = '#7C79BA';
export const VOID_6 = '#9D99CB';
export const VOID_7 = '#DEDCED';
export const VOID_8 = '#8C86FF';

export const VOID_NEON = '#D7597B';
export const VOID_NEON_ = '#8C86FF';
export const CANDY_NEON = '#FF6A84';

export const VOXO_WHITE = '#FFFFFF';
export const VOXO_DARK = '#0B1528';
export const MATRIX_NEON = '#56F6CE';
export const OMEGA_KEY = '#091011';
