import styled from 'styled-components';
import { VoxColors } from '../../themes/Colors';
import { Button } from '../Button';
import * as fonts from 'assets/CSSPalette/fonts';
import { Link } from 'react-router-dom';

export const AltLink = styled.span`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
`;
export const Desktop = styled.span`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const StyledNavBar = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transform: ${props => (props.visible ? 'unset' : 'translateY(-100%)')};
  padding: 0.75rem 0;
  z-index: 450;
  transition: transform 0.4s ease, background-color 0.4s ease, box-shadow 0.4s ease, -webkit-transform 0.4s ease,
    -webkit-box-shadow 0.4s ease;
  background-color: ${props => {
    if (props.black) {
      return '#091011';
    }
    if (props.visible) {
      return VoxColors.dark;
    }
    return 'unset';
  }};
`;

const StyledButton = styled(Button)`
  max-width: 160px;
  padding: 10px;
  font-size: 14px;
  > img {
    margin-left: 2px;
    transition: all 0.1s ease-in;
  }
  @media screen and (max-width: 1024px) {
    margin: unset;
    margin-top: 15px;
    max-width: 160px;
    width: 100%;
  }
  > div {
    display: block !important;
  }
`;
export const StyledButtonDropdown = styled.div`
  width: 100%;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5);
  border-radius: 0px 0px 5px 5px;
  color: #0e1c34;
  font-size: 14px;
  letter-spacing: 0.01em;
  position: absolute;
  top: 100%;
  display: none !important;
  cursor: pointer;
  > div {
    padding: 11px 20px;
    transition: all 0.1s ease-in;
    font-weight: 500;
    &:hover {
      color: ${VoxColors.candyNeon};
    }
  }
`;
export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${StyledButton} {
      > img {
        &:not(:first-child) {
          transform: rotate(180deg);
        }
      }
    }
    ${StyledButtonDropdown} {
      display: flex !important;
    }
  }
`;

const StyledNavBarDropdownItemButton = styled(Button)`
  width: 107px;
  padding: 10px;
  font-size: 14px;
  border: unset;
  font-family: 'Outage';
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-shadow: 0px 0.418303px 30px rgb(87 147 255 / 20%);
  text-transform: uppercase;
  padding: unset;
  > img {
    margin-left: 2px;
    transition: all 0.1s ease-in;
  }
  @media screen and (max-width: 1024px) {
    margin: unset;
    margin-top: 15px;
    max-width: 160px;
    width: 100%;
  }
  > div {
    display: block !important;
    &:hover {
      color: ${VoxColors.voidNeon};
    }
  }
`;
export const StyledNavBarDropdownItemButtonDropdown = styled.div`
  width: 203px;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5);
  border-radius: 10px;
  color: #0e1c34;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.01em;
  position: absolute;
  top: 40px;
  left: 4px;
  display: none !important;
  cursor: pointer;
  z-index: 999;
  padding: 13px 0;
  > div {
    width: 100%;
    height: 30px;
    transition: all 0.1s ease-in;
    font-weight: 600;
    color: ${VoxColors.tron1};
      > a {
        transition: unset;
        line-height: 30px;
        text-align: unset;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: ${VoxColors.tron1} !important;
        &:hover {
          background-color: ${VoxColors.tron8};
          color: ${VoxColors.tron1};
        }
    }
    &:hover {
      background-color: ${VoxColors.tron8};
      color: ${VoxColors.tron1};
    }
  }
`;

export const StyledNavBarDropdownItemButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${StyledNavBarDropdownItemButton} {
      > img {
        &:not(:first-child) {
          transform: rotate(180deg);
        }
      }
    }
    ${StyledNavBarDropdownItemButtonDropdown} {
      display: flex !important;
    }
  }
`;

const NavBarItems = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media screen and (max-width: 1024px) {
    height: 42px;
  }
`;
const CloseMenuButton = styled.div`
  width: 35px;
  margin-left: 5px;
  font-size: 33px;
  color: ${VoxColors.candy6};
  position: relative;
  top: -8px;
  right: -5px;
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  a {
    /* margin-left: 50px; */
    font-family: 'Outage';
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    color: ${VoxColors.white};
    text-shadow: 0px 0.418303px 30px rgba(87, 147, 255, 0.2);
    transition: color 0.2s ease-out;
    text-decoration: none;
    margin-right: 1.5rem;
    white-space: nowrap;
    display: block;
    text-align: center;

    &:first-child {
      margin-left: 25px;
      @media screen and (max-width: 1024px) {
        margin-left: unset;
      }
    }

    &:hover,
    &.active {
      color: ${VoxColors.matrixNeon};
    }
  }

  @media screen and (max-width: 1300px) {
    a {
      margin-left: 1.5rem;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 1024px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 200px 0 120px;
    border-bottom-left-radius: 50px;
    background-color: ${VoxColors.dark};
    box-shadow: 0 0 35px rgb(171 155 233 / 25%);
    height: 80vh;
    z-index: 4;
    transform: ${props => (props.toggleOn ? 'translateY(0)' : 'translateY(-100%)')};
    transition: transform 0.4s ease-out, box-shadow 0.4s ease-out;

    a {
      transition: all 0.4s ease-out;
      margin: 0 0 40px;
      @media screen and (max-height: 450px) {
        margin: 0 0 30px;
      }
    }
  }

  /* @media screen and (max-height: 550px) {
    height: 100vh;
    border-bottom-left-radius: 0px;
  } */
`;

const Divider = styled.img`
  margin-right: 20px;
  margin-left: 20px;
  @media screen and (max-width: 1024px) {
    transform: rotate(90deg);
    position: relative;
    left: 46%;
    margin: unset;
  }
`;

const Logo = styled.div`
  position: relative;
  left: -27px;
  display: inline-block;
  z-index: 5;
  margin-top: 10px;

  img {
    width: 190px;
    height: auto;
  }

  @media screen and (max-width: 1300px) {
    width: 185px;
    left: 2px;
    margin-left: 20px;

    img {
      width: 185px;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-left: auto;
  align-items: center;
  z-index: 5;
  @media screen and (max-width: 1300px) {
    margin-left: 2%;
  }

  div {
    display: flex;
    @media screen and (max-width: 1024px) {
      display: flex;
      margin: 0 auto;
    }
  }

  a {
    color: ${VoxColors.white};
  }

  .social {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-right: 0;
    border-radius: 50%;
    overflow: hidden;
    transform-origin: center;
    transition: transform 0.3s ease-out, filter 0.3s ease-out;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      height: 100%;
      background: linear-gradient(86.26deg, #8c86ff 1.5%, #ab5fb4 25%, #cc3767 50%, #ab5fb4 75%, #8c86ff 98.5%);
      z-index: 0;
      transition: left 0.3s ease-out;
    }

    img {
      position: relative;
      z-index: 1;
    }

    &:hover {
      box-shadow: 0px 1px 10px rgba($voidneon, 0.5);

      &::before {
        left: -100%;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .social {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;
    display: block;

    .social {
      margin-left: 8px;
      margin: 0 8px !important;
      width: 50px;
      height: 50px;
      &::first-child {
        margin: 0 8px;
      }
    }
    .opensea-button {
      width: 70%;
    }
  }

  @media screen and (max-width: 640px) {
    .social {
      &:first-of-type {
      }
    }
  }
`;

const Toggle = styled.div`
  display: none;
  position: absolute;
  right: 30px;
  top: 12px;
  width: ${({ toggleOn }) => (toggleOn ? '35px' : '30px')};
  height: 30px;
  padding: 3px;
  margin: 6px 0 0 20px;
  z-index: 6;

  span {
    position: absolute;
    right: 0;
    height: 3px;
    background: ${VoxColors.candy6};
    border-radius: 3px;

    &:nth-child(1) {
      top: 6px;
      width: 20px;
    }
    &:nth-child(2) {
      top: 15px;
      width: 24px;
    }
    &:nth-child(3) {
      top: 24px;
      width: 12px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const InnerMenuItem = styled(Link)`
  margin-left: 0 !important;
  margin-right: 25px !important;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: ${fonts.SIZE_S} !important;
  font-weight: ${fonts.WEIGHT_BOLD} !important;
`;

const OpenseaButton = styled.div`
  > a {
    background: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 15px;
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 5px 8px;
    line-height: 15px;
    > div {
      margin-left: 6px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-weight: 600;
      > span {
        font-size: 8px;
        color: #a3a3a3;
        line-height: 8px;
      }
    }
    &:hover {
      color: #000;
    }
  }
`;

const NavBarItemNewButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 27px;
  width: 27px;
  height: 13px;
  background: #56F6CE;
  border-radius: 2px;
  margin-left: 13px;
  
  > span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
  }
`;

export {
  StyledNavBar,
  NavBarItems,
  Logo,
  Nav,
  Links,
  Toggle,
  Divider,
  StyledButton,
  InnerMenuItem,
  CloseMenuButton,
  OpenseaButton,
  StyledNavBarDropdownItemButton,
  NavBarItemNewButton
};
