import {
    LoadingText,
    VoxoGalleryWrapper,
    VoxoGridItem,
    VoxoGridItemArchetype,
    VoxoGridItemImage,
    VoxoGridItemImageContainer,
    VoxoGridItemInfo,
    VoxosGrid,
} from 'containers/GalleryPage/gallery.styled';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { padLeadingZeros } from 'utils/numberFormat';
import { ARWEAVE } from '../../../utils/constants';

const VoxoGallery = ({ voxos, showMoreData, hasMore, dataLength }) => {
  return (
    <VoxoGalleryWrapper>
      <InfiniteScroll
        dataLength={voxos.length}
        next={showMoreData}
        hasMore={hasMore}
        loader={hasMore ? <LoadingText>Loading...</LoadingText> : null}
      >
        <VoxosGrid>
          {voxos.map((item, i) => (
            <VoxoGridItem key={i}>
              <NavLink to={`/voxo/${item.id}`}>
                  <VoxoGridItemImageContainer>
                      <VoxoGridItemImage src={`${ARWEAVE.GALLERY}/${item.id}.png`} />
                  </VoxoGridItemImageContainer>
                <VoxoGridItemInfo>
                  Voxo #{padLeadingZeros(item.id, 4)}{' '}
                  <span>
                    Rank <span>{item.r}</span>
                  </span>
                </VoxoGridItemInfo>
                <VoxoGridItemArchetype>{item.t}</VoxoGridItemArchetype>
              </NavLink>
            </VoxoGridItem>
          ))}
        </VoxosGrid>
      </InfiniteScroll>
    </VoxoGalleryWrapper>
  );
};
export default VoxoGallery;
