import omega from 'assets/images/archetypes/omega/OMEGA.png';

import theSeed from 'assets/images/archetypes/ascendii/The Seed.png';
import theBetrayal from 'assets/images/archetypes/ascendii/The Betrayal.png';
import theMany from 'assets/images/archetypes/ascendii/The Many.png';
import theSentinel from 'assets/images/archetypes/ascendii/The Sentinel.png';
import thePolarity from 'assets/images/archetypes/ascendii/The Polarity.png';
import theAbyss from 'assets/images/archetypes/ascendii/The Abyss.png';
import theJourney from 'assets/images/archetypes/ascendii/The Journey.png';
import theFee from 'assets/images/archetypes/ascendii/The Fee.png';

import velvetBull from 'assets/images/archetypes/exomorphs/Velvet Bull.png';
import tiny from 'assets/images/archetypes/exomorphs/Tiny.png';
import orbiteer from 'assets/images/archetypes/exomorphs/Orbiteer.png';
import whiskers from 'assets/images/archetypes/exomorphs/Whiskers.png';

import hondaLuca from 'assets/images/archetypes/augs/Honda Luca.png';
import valkyrieZero from 'assets/images/archetypes/augs/Valkyrie Zero.png';
import rigPirate from 'assets/images/archetypes/augs/Rig Pirate.png';
import cableMonk from 'assets/images/archetypes/augs/Cable Monk.png';

import streetSamurai from 'assets/images/archetypes/sapiens/Street samurai.png';
import photonranger from 'assets/images/archetypes/sapiens/Photon ranger.png';
import mechdecker from 'assets/images/archetypes/sapiens/Mech decker.png';
import starcatcher from 'assets/images/archetypes/sapiens/Starcatcher.png';
import moongazer from 'assets/images/archetypes/sapiens/Moongazer.png';
import eliteress from 'assets/images/archetypes/sapiens/Eliteress.png';

import gadgeteer from 'assets/images/archetypes/robits/Gadgeteer.png';
import Ch3f30 from 'assets/images/archetypes/robits/Ch3f 3.0.png';
import deliverobit from 'assets/images/archetypes/robits/Deliverobit.png';
import floristobit from 'assets/images/archetypes/robits/Floristobit.png';
import medicaller from 'assets/images/archetypes/robits/Medicaller.png';
import iceHauler from 'assets/images/archetypes/robits/Ice Hauler.png';
import HV808 from 'assets/images/archetypes/robits/HV-808.png';
import HVClothier from 'assets/images/archetypes/robits/HV-Clothier.png';
import HVUN1T from 'assets/images/archetypes/robits/HV-UN-1T.png';

export const archetypes = {
  omega: [
    {
      img: omega,
      name: 'Deus',
      fraction: 'Ω',
      prof: 'Programmer of ideas',
      legend: [
        {
          text: 'It’s been 100 Loops since Omega disappeared.',
          style: '',
        },
        { text: '', style: '' },
        {
          text: 'According to the oldest glim-glyphs in Luma Systemica’s library, Omega ‘shaped’ a pocket of the infinite Cypherine Space to make it safe and ensure life would thrive. We’re told that Omega gathered the energy of a neutron star and scattered it across the system, imbuing planets and moons with NRG, an energy source that became the foundation of modern Voxo society.',
          style: '',
        },
        { text: '', style: '' },
        {
          text: 'All these Loops later, the Ascendii are responsible for most of what we know about Omega, with myths and legends making up the parts between fact.',
          style: '',
        },
        { text: '', style: '' },
        {
          text: 'The question of why Omega abandoned its home nags in the back of every Voxo mind. Whether it’s a Hindsight Scholar\'s desperate to gain from the past, or a Polarion’s Foil Fleeter, eager to discover Omega’s new home - we’re drawn to the mystery that shaped our System.',
          style: '',
        },
      ],
    },
  ],
  ascendii: [
    {
      img: theSeed,
      name: 'The Seed',
      prof: 'Beginning',
      fraction: 'Polarion',
      legend: [
        {
          text: 'Root of the life lands; Sourcestone.',
          style: '',
        },
        { text: '', style: '' },
        { text: '”Bend the seasons to your will, calm the tides and bid them: deny the moons!', style: 'italic' },
        {
          text: 'Do what it takes to bring beginnings, first Seed - shelter us from Starfall, peer through us with the mark of the paw.“',
          style: 'italic',
        },
      ],
    },
    {
      img: theBetrayal,
      name: 'The Betrayal',
      fraction: 'Luma Systemica',
      prof: 'Consequence',
      legend: [
        { text: 'The Scar that shines brightest; Unforgetter; The coiling Calm.', style: '' },
        { text: '', style: '' },
        {
          text: `”Wronged when the centre-stars were shackled;`,
          style: 'italic',
        },
        {
          text: `reminded of it each time Starfall paints the sky.`,
          style: 'italic',
        },
        { text: '', style: ' ' },
        {
          text: `The journey is long and the task asks too much,`,
          style: 'italic',
        },
        {
          text: `but your tail is strong and the path it carves will brighten our nights.”`,
          style: 'italic',
        },
      ],
    },
    {
      img: theMany,
      name: 'The Many',
      prof: 'Togetherness',
      fraction: 'The Shen Tanks',
      legend: [
        { text: 'Flame to the few; Heart of home.', style: '' },
        { text: '', style: '' },
        {
          text: `”No Shen bonfire perishes alone, we extinguish together because you teach us The Momentary Way.
      `,
          style: 'italic',
        },
        {
          text: `Yesterday’s harbour is given to the Cypherine Swathe.`,
          style: 'italic',
        },
        {
          text: 'Cold and Correct.',
          style: 'italic',
        },
        {
          text: `Collected at our heels.”`,
          style: 'italic',
        },
      ],
    },
    {
      img: theSentinel,
      name: 'The Sentinel',
      prof: 'Shelter',
      fraction: 'The Shen Tanks',
      legend: [
        { text: 'Bird on every wing, Wind weaver.', style: '' },
        { text: '', style: '' },
        { text: `”Guardian on a whim; keenest eye in all the skies.`, style: 'italic' },
        { text: '', style: '' },
        {
          text: `Dull your talons a while, perch for this moment and revel with us.`,
          style: 'italic',
        },
        {
          text: `The void is vast and bright with peril, but here in the dark half we rest till daybreak”`,
          style: 'italic',
        },
      ],
    },
    {
      img: thePolarity,
      name: 'The Polarity',
      prof: 'Uncertainty',
      fraction: 'Luma Systemica',
      legend: [
        { text: 'The Doubt that clouds the hereafter; Watcher of wonts that once were.', style: '' },
        { text: '', style: '' },
        {
          text: `”Challenge the bonds that bind us, pardon the parts that cling to the past, embolden those that fear the future.`,
          style: 'italic',
        },
        { text: '', style: '' },
        {
          text: `Remind us -even when we’re distant- that we are trussed together. Held in place despite our polarity.”`,
          style: 'italic',
        },
      ],
    },
    {
      img: theAbyss,
      name: 'The Abyss',
      prof: 'Impermanence',
      fraction: 'Corpo Vendrix',
      legend: [
        { text: 'Ensign of the Unfathomable Vast; translator of ideas irreversible.', style: '' },
        { text: '', style: '' },
        {
          text: `”We shroud the infinite because it’s been known to unravel transient minds.`,
          style: 'italic',
        },
        {
          text: `You, Abyss, must keep the night and the stars and every bit that sits behind them.`,
          style: 'italic',
        },
        {
          text: `Hold each dweller of the beyond and grant us gentle glimpses.`,
          style: 'italic',
        },
        { text: '', style: '' },
        { text: `Keep us from what we mustn’t grasp; so that we’ll appreciate what we can.”`, style: 'italic' },
      ],
    },
    {
      img: theJourney,
      name: 'The Journey',
      prof: 'Urge',
      fraction: 'Polarion',
      legend: [
        { text: 'Face of the voyage; Compass of countless paths.', style: '' },
        { text: '', style: '' },
        {
          text: `“Ahead, ahead, to the pines that shelter;
          and then beyond with their tinder in tow.`,
          style: 'italic',
        },
        {
          text: `and then beyond with their tinder in tow.`,
          style: 'italic',
        },
        { text: '', style: '' },
        {
          text: `A bright and searing search you’ll render; 
          with a spark of Neverquell for lingering glow.“`,
          style: 'italic',
        },
        {
          text: `with a spark of Neverquell for lingering glow.“`,
          style: 'italic',
        },
      ],
    },
    {
      img: theFee,
      name: 'The Fee',
      prof: 'Burden',
      fraction: 'Corpo Vendrix',
      legend: [
        { text: 'Ferryman on the drift boat of yearning; ghost of the heart.', style: '' },
        { text: '', style: '' },
        {
          text: `”You must sail, always before they are ready. Before they think to prepare.`,
          style: 'italic',
        },
        {
          text: `The colour you take from them is payment, they will fade -only a little- and remember`,
          style: 'italic',
        },
        {
          text: `fondly what it was to know your warmth.”`,
          style: 'italic',
        },
      ],
    },
  ],
  exomorphs: [
    {
      img: velvetBull,
      name: 'Velvet Bull',
      fraction: 'The Shen Tanks',
      prof: 'Subject: Force',
      legend:
        'Confused and made volatile by the shift to Exomorph form, Velvet hid himself away for many years before The Many discovered him and dismantled the labyrinthine constructs he’d erected to achieve isolation.',
    },
    {
      img: tiny,
      name: 'Tiny',
      fraction: 'Corpo Vendrix',
      prof: 'Subject: Gain',
      legend:
        'Finding an unexpected alliance with The Fee when Exo immortality robbed him of his friends and family, Tiny proved himself valuable to the Corpo Vendrix faction by bargaining for land and power a time when the Shen Tanks controlled the vast majority.',
    },
    {
      img: orbiteer,
      name: 'Orbiteer',
      fraction: 'Polarion',
      prof: 'Subject: Soar',
      legend:
        'An unparalled pilot with a natural talent for phaseflight, Orbiteer was the first being in the Cypherverse to cross the Starfall threshold. On the other side she found The Seed and formed a connection that would define the code of Polarion.',
    },
    {
      img: whiskers,
      name: 'Whiskers',
      fraction: 'Luma Systemica',
      prof: 'Subject: Prowl',
      legend:
        'Stories of the quietest feline in the Cypherverse always feature her unique ability to be in two places at the same time. Little is known about how it works, but it was The Polarity who taught her to control it and focus her second-self into the Luma Systemica digital nexus.',
    },
  ],
  augs: [
    {
      img: hondaLuca,
      name: 'Honda Luca',
      fraction: 'The Shen Tanks',
      prof: 'Tide Enforcer',
      legend:
        'Just the right amount of brains and brawn to squeeze into a position of power during the hotter times on the sister-planets. Luca has a gentle heart but runs the streets with a clenched fist.',
    },
    {
      img: valkyrieZero,
      name: 'Valkyrie Zero',
      fraction: 'Polarion',
      prof: 'Sky Protector',
      legend:
        'Protectors of the Cypherverse foil-fleet, the Valkyr pilots work tirelessly to fend off Rig Pirates looking to loot NRG and starfall materials from convoys travelling between the planets.',
    },
    {
      img: rigPirate,
      name: 'Rig Pirate',
      fraction: 'Corpo Vendrix',
      prof: 'NRG Hoarder',
      legend:
        'Opportunistic, mostly unlawful, doing what they must to survive. Rig Pirates raid the foil fleets to sustain themselves and have noble connection to the StarCatchers - who they freely share Starfall information that they\'ve hacked from the fleet.',
    },
    {
      img: cableMonk,
      name: 'Cable Monk',
      fraction: 'Luma Systemica',
      prof: 'Undercity Upkeep',
      legend:
        'Deep beneath the surface of the city planet, Cable Monks tether themselves to the planet\'s NRG to deny sleep and provide perpetual maintenance to the dwellers above. Though their goals couldn\'t be further from one another, these selfless individuals utilise the same technology as the hedonistic Mech Deckers.',
    },
  ],
  sapiens: [
    {
      img: streetSamurai,
      name: 'Street samurai',
      fraction: 'The Shen Tanks',
      prof: 'Retired Cutsmith',
      legend:
        'The Sapien city corridors of the sister-planets were wild for a time, and back then \'Cutters\' like the Street Samurai were the closest thing to protection that NRG could buy. These later days of life are mostly filled with rest, but his blade and wits remain plasma sharp. Just in case.',
    },
    {
      img: moongazer,
      name: 'Moongazer',
      fraction: 'Luma Systemica',
      prof: 'Umbral Scholar',
      legend:
        'Turning their backs to the anomalous star(s) at the centre of the Cypherverse, moongazers focus on the two moons orbiting the Ascendii outerplanet - drawing wisdom from the secrets that their oscillating trajectories write in the sky.',
    },
    {
      img: eliteress,
      name: 'Eliteress',
      fraction: 'Corpo Vendrix',
      prof: 'Matriarch Prime',
      legend:
        'Matriarchs who govern the sprawling surface of the Cypherverse\'s gargantuan Ecumenopolis. A city planet protected by the resource-rich rocks that it mines daily. Eliteresses live with the burden of balancing growth and preservation.',
    },
    {
      img: starcatcher,
      name: 'Starcatcher',
      fraction: 'The Shen Tanks',
      prof: 'Celestial Heiress',
      legend:
        'Always aware of the Second Star\'s movements and present the moment it erupts - Starcatchers phase through the galaxy warning settlements about impending starfall showers, funding their work by collecting starfall resources and trading them at the next port.',
    },
    {
      img: mechdecker,
      name: 'Mech decker',
      fraction: 'Luma Systemica',
      prof: 'Cyph Nomad',
      legend:
        'Running on the purest distilled digital NRG, Mech Deckers use their eponymous decking tools to design and sculpt the space around them, immersing their clients in blissful moments of augmented real-Cyph. ',
    },
    {
      img: photonranger,
      name: 'Photon ranger',
      fraction: 'Polarion',
      prof: 'Luminosity trapper',
      legend:
        'Driven by the Second Star\'s unseeable light, Photon Rangers have dedicated their lives to the mysterious bright matter that informs so much of the Cypherverses makeup but, as of yet, remains entirely impossible to detect. One day they’ll trap it, they’re sure of that much. ',
    },
  ],
  robits: [
    {
      img: gadgeteer,
      name: 'Gadgeteer',
      fraction: 'The Shen Tanks',
      prof: 'Watcher & Waiter',
      legend:
        'Long before the Robit discovery of laziness, Gadgeteer had a talent for doing very little.. or at least, *seeming* to do very little. In truth, Gadgeteer is the CypherVerse\'s keenest and most patient observer - a watcher and tracker of things. In the effort to reveal the Omega\'s final form, his sharp eye will no doubt prove invaluable.',
    },
    {
      img: deliverobit,
      name: 'Deliverobit',
      fraction: 'Luma Systemica',
      prof: 'Boost Courier',
      legend:
        'Running low-poly NRG through the heart of the Abyss Sector is no small thing, which is why D has a mark VI helio-thruster (built for a low orbit Cypherfoil) strapped to her back. The goggles are just for show.',
    },
    {
      img: HV808,
      name: 'HV-808',
      fraction: 'Polarion',
      prof: 'Moon Forger',
      legend:
        'Compelled to study by the Moon scholar sapiens of the outer planet, 808\'s years of learning have granted it intelligence enough to craft and wield the Ascendii second moon\'s turquoise blade of myth.',
    },
    {
      img: floristobit,
      name: 'Floristobit',
      fraction: 'Polarion',
      prof: 'Travelling Haven',
      legend:
        'First of the Seed\'s founding forms - Floristobit is a seeker, carrier, and nurturer of potential. Rarely on one planet for more than a few cycles, she constantly pushes the periphery of the known Cypherverse outward with a travelling troupe of planters and gardeneers.',
    },
    {
      img: medicaller,
      name: 'Medicaller',
      fraction: 'Corpo Vendrix',
      prof: 'Rocket Doc',
      legend:
        'Trained as both a phaseshift engineer and an organic-life healer, the Medicaller operates a single-robit service to wealthy Corpo elites and elitresses looking to visit a starfall shower and collect precious resources.',
    },
    {
      img: iceHauler,
      name: 'Ice Hauler',
      fraction: 'Corpo Vendrix',
      prof: 'Coolant Collector',
      legend:
        'Formed when the sister-planets in midspace were being teraformed, Ice Haulers travelled between the two nascent masses carving and carrying vast ice block necessary to cool the superhot planetscaping machines.',
    },
    {
      img: HVUN1T,
      name: 'HV-UN-1T',
      fraction: 'Corpo Vendrix',
      prof: 'Body Positron',
      legend:
        'Colliding all manner of matter inside the particle pressurising parts of its torso, UN-1T provided vital structural insight when the mid-space sister-planet project was first being conceived.',
    },
    {
      img: HVClothier,
      name: 'HV-Clothier',
      fraction: 'Corpo Vendrix',
      prof: 'Nano Weaver',
      legend:
        'Formed as a factory floor cleaner, collecting offcuts from the garbtower\'s thread-spinner software. Clothier decided to reform itself using the resources it had in abundance and now moonlights as a fashionista when the working day is done.',
    },
    {
      img: Ch3f30,
      name: 'Ch3f 3.0',
      fraction: 'Polarion',
      prof: 'Food-o-mateur',
      legend:
        'Head chef on the Astran flagship Cypherfoil \'Ever Forward\', Ch3f\'s memorybanks aren\'t what they used to be because he\'s reformatted his brainbox twice in order to reconfigure for necessary career changes. The foodish headpieces are mostly there to remind him what\'s cooking each day.',
    },
  ],
};
