export const omegaList = [
  "#3017"
];

export const ascendiiList = [
  "#3152",
  "#3040",
  "#4500",
  "#4400",
  "#2960",
  "#1350",
  "#0585",
  "#0713"
];

export const exomorphsList = [
  "#5268",
  "#0252",
  "#1054",
  "#1961",
  "#2565",
  "#3067",
  "#0149",
  "#3013"
];

export const augsList = [
  "#0676",
  "#4820",
  "#0678",
  "#1453",
  "#1568",
  "#3041",
  "#2196",
  "#2326",
  "#0750",
  "#3572",
  "#5239",
  "#4530"
];

export const sapiensList = [
  "#5144",
  "#0038",
  "#0063",
  "#0452",
  "#0515",
  "#0422",
  "#0698",
  "#0642",
  "#2286",
  "#0661",
  "#0718"
];

export const robitsList = [
  "#0899",
  "#3180",
  "#0005",
  "#0127",
  "#0055",
  "#0138",
  "#0141",
  "#0142",
  "#0004",
  "#1519",
  "#5435",
  "#0461",
  "#0380",
  "#1823",
  "#0007",
  "#0457",
  "#0289",
  "#1782"
];

export const fullList = [...omegaList, ...robitsList, ...sapiensList, ...ascendiiList, ...augsList, ...exomorphsList];
