export const WEIGHT_THIN = 300;
export const WEIGHT_MEDIUM = 400;
export const WEIGHT_SEMI_BOLD = 600;
export const WEIGHT_BOLD = 700;

export const SIZE_XXXS = "10px";
export const SIZE_XXS = "12px";
export const SIZE_XS = "14px";
export const SIZE_S = "15px";
export const SIZE_M = "16px";
export const SIZE_L = "20px";
export const SIZE_XL = "24px";
export const SIZE_XXL = "30px";
export const SIZE_XXXL = "38px";

export const LINE_HEIGHT_XXS = "20px";
export const LINE_HEIGHT_XS = "22px";
export const LINE_HEIGHT_S = "24px";
export const LINE_HEIGHT_M = "28px";
export const LINE_HEIGHT_L = "32px";
export const LINE_HEIGHT_XL = "38px";
export const LINE_HEIGHT_XXL = "46px";
