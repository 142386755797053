import React from "react";
import { ReactComponent as CloseIcon } from "assets/icons/close_nofilter.svg";
import { Modal } from "./fullScreenIframe.styled";

export const FullScreenIframe = ({ link, toggleShowFullscreen }) => {
  console.log(link);
  return (
    <Modal>
      <iframe src={link}></iframe>
      <div onClick={() => toggleShowFullscreen(false)}>
        <CloseIcon />
      </div>
    </Modal>
  );
};
