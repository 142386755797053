import React from 'react';
import Distribution from 'assets/images/faction_distribution.svg';

const HowSection = () => {
  return (
    <section className="how" id="how-it-works">
      <div className="container">
        <h2>HOW IT WORKS</h2>
        <div className="tiles">
          <div className="split">
            <div className="left">
              <div className="tile stats">
                <h3>RARITies & MODifiers</h3>
                <ul>
                  <li>
                    <span className="highlight">6312</span> unique characters
                  </li>
                  <li>
                    <span className="highlight">6</span> species
                  </li>
                  <li>
                    <span className="highlight">4</span> factions
                  </li>
                </ul>

                <p className="section-label lg">
                  RARITY TIERS | <span className="highlight">3</span>
                </p>
                <figure style={{ padding: '0 20 px', margin: '0 0 -30 px' }}>
                  <img src={"assets/images/rarity.svg"} alt="Rare - 80%, SuperRare - 19%, UltraRare - 1%" />
                </figure>

                <p className="section-label lg">
                  Species distribution | <span className="highlight">6</span>
                </p>
                <div className="tag">
                  <span>Omega</span>
                  <span className="highlight">1</span>
                </div>
                <div className="tag">
                  <span>Ascendii</span>
                  <span className="highlight">8</span>
                </div>
                <div className="tag">
                  <span>Exomorphs</span>
                  <span className="highlight">32</span>
                </div>
                <div className="tag">
                  <span>Augs</span>
                  <span className="highlight">128</span>
                </div>
                <div className="tag">
                  <span>Sapiens</span>
                  <span className="highlight">2048</span>
                </div>
                <div className="tag">
                  <span>Robits</span>
                  <span className="highlight">4095</span>
                </div>

                <p className="section-label lg">
                  FACtion distribution | <span className="highlight">4</span>
                </p>
                <figure style={{ margin: '40px 0 0' }}>
                  <img
                    src={Distribution}
                    alt="The Shen Tanks - 10%, Polarion - 20%, Corpo Vendrix - 20%, Luma Systemica - 50%"
                  />
                </figure>

                <p className="section-label lg">
                  LOADOUT distribution | <span className="highlight">4</span>
                </p>
                <div className="loadout-list">
                  <div className="loadout-bar">
                    <span className="label">Intelligence</span>
                    <span className="bar" style={{ width: '15%', maxWidth: '40px' }}></span>
                    <span className="highlight">??</span>
                  </div>
                  <div className="loadout-bar">
                    <span className="label">Strength</span>
                    <span className="bar" style={{ width: '40%', maxWidth: '125px' }}></span>
                    <span className="highlight">??</span>
                  </div>
                  <div className="loadout-bar">
                    <span className="label">Cool</span>
                    <span className="bar" style={{ width: '50%', maxWidth: '185px' }}></span>
                    <span className="highlight">??</span>
                  </div>
                  <div className="loadout-bar">
                    <span className="label">Heart</span>
                    <span className="bar" style={{ width: '35%', maxWidth: '110px' }}></span>
                    <span className="highlight">??</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="tile jazzy">
                <h3>
                  3d interactive view with <br />
                  audio + Jazzy features
                </h3>
                <figure>
                  <img src={"assets/images/jazzy.svg"} alt="" />
                </figure>
                <p>
                  We’ve worked hard to create a <strong>custom 3JS frame</strong> that lets you view your Voxo from any
                  angle.VoxoDeus is the first interactive project to attempt this kind of thing at this scale, adding
                  cinematic effects and plenty of post-processing to really make things pop.
                </p>

                <p>
                  We also worked with two composers who produced and <strong>embedded ambient background tracks</strong>{' '}
                  to really immerse you in the experience. This is the <strong>next generation</strong> of experiencing
                  and interacting with NFTs.
                </p>
              </div>

              <div className="tile backgrounds">
                <h3>
                  DYNAMIC <br />
                  ENVIRONMENTS
                </h3>
                <figure>
                  <img src={"assets/images/backgrounds.svg"} alt="" />
                </figure>
                <p>
                  Each character comes with a 3D diorama that matches its faction. Some also have extra background items and pets. Plus, look out for <strong>limited runs</strong> of extra premium 3D dioramas added to some Voxos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowSection;
