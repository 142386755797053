import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as BackIcon } from 'assets/icons/chevron_left.svg';
import { ReactComponent as RotationIcon } from 'assets/images/icon/3d-rotation.svg';
import { ReactComponent as PauseIcon } from 'assets/images/icon/pause.svg';

import Footer from 'components/footer';

import {
  BackButton,
  CharacterDetailsPageWrapper,
  Controls,
  HorizontalLine,
  OwnerData,
  OwnerDataItem,
  VoxoId,
  VoxoPreview,
} from './characterDetailsPage.styled';

import { LoadingDetailsState } from './LoadingDetailsState';
import { padLeadingZeros } from 'utils/numberFormat';
import VoxoData from './VoxoData';
import { NavLink, useParams } from 'react-router-dom';
import FastNavigation from './VoxoData/FastNavigation';
import { textTrunc } from 'utils/textFormat';
import CopyToClipboard from 'react-copy-to-clipboard';
import { disableScroll, enableScroll } from 'utils/windowEvents';
import { getUserName } from '../../services/openSeaService';
import { ARWEAVE } from '../../utils/constants';

const CharacterDetailsPage = () => {
  const { voxoId } = useParams();

  const [voxoData, setVoxoData] = useState({});
  const [voxoNav, setVoxoNavData] = useState([]);
  const [ownerMinterData, setOwnerMinterData] = useState({ ownerName: '', minterName: '' });
  const [ownersAddress, setOwnerAddress] = useState({ ownerAddress: '', minterAddress: '' });

  useEffect(() => {
    axios.get(`${ARWEAVE.METADATA}/${voxoId}.json`).then(({ data: voxoData }) => {
      if (voxoData) {
        setVoxoData(voxoData);

        const tokenId = parseInt(voxoId);

        setVoxoNavData([
          voxoId > 1 ? { id: tokenId - 1, image: `${ARWEAVE.NAV_THUMBNAILS}/${tokenId - 1}.png` } : null,
          voxoId < 6312 ? { id: tokenId + 1, image: `${ARWEAVE.NAV_THUMBNAILS}/${tokenId + 1}.png` } : null,
        ]);
        axios
          .post('https://api.thegraph.com/subgraphs/name/araa47/voxodeusexplorer', {
            query: `{
                voxoToken(id: ${voxoId}){user{id}minter{id}}}`,
          })
          .then(({ data: { data: ownerData } }) => {
            const {
              voxoToken: { minter = '', user: owner = '' },
            } = ownerData;
            setOwnerAddress({ ownerAddress: owner.id, minterAddress: minter.id });
            getUserName(minter.id).then(({ data: { data: minterData } }) => {
              if (minterData) {
                const minterName = minterData.user?.username;

                getUserName(owner.id).then(({ data: { data: ownerData } }) => {
                  if (ownerData) {
                    let ownerName = ownerData.user?.username;
                    setOwnerMinterData({
                      minterName: minterName || minter.id,
                      ownerName: ownerName || owner.id,
                    });
                  }
                });
              }
            });
          });
      }
    });
  }, [voxoId]);

  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    if (!showOverlay) {
      document.body.onmousedown = e => {
        if (e.button === 1) {
          disableScroll();
        }
      };

      document.body.onmouseup = e => {
        if (e.button === 1) {
          enableScroll();
        }
      };
    } else {
      enableScroll();
      document.body.onmousedown = () => {
      };
    }
  }, [showOverlay]);
  return (
    <CharacterDetailsPageWrapper>
      {voxoData.token_id ? (
        <>
          <Controls>
            <NavLink to='/gallery'>
              <BackButton>
                <BackIcon /> Back to gallery
              </BackButton>
            </NavLink>
            <VoxoId>
              <HorizontalLine />
              VOXO #{padLeadingZeros(voxoId, 4)}
            </VoxoId>
          </Controls>
          <VoxoPreview showOverlay={showOverlay}>
            <iframe src={ voxoData.animation_url }></iframe>
            {showOverlay ? (
              <a onClick={() => setShowOverlay(false)} className={`togglebig`}>
                <RotationIcon />
              </a>
            ) : (
              <div onClick={() => setShowOverlay(true)} className='toggleinteract'>
                <PauseIcon />
              </div>
            )}
            <OwnerData>
              <OwnerDataItem>
                <span>minted by</span>
                <CopyToClipboard text={ownersAddress.minterAddress}>
                  <button>{textTrunc(ownerMinterData.minterName)}</button>
                </CopyToClipboard>
              </OwnerDataItem>
              <OwnerDataItem>
                <span>owned by</span>
                <CopyToClipboard text={ownersAddress.ownerAddress}>
                  <button>{textTrunc(ownerMinterData.ownerName)}</button>
                </CopyToClipboard>
              </OwnerDataItem>
            </OwnerData>
          </VoxoPreview>
          <VoxoData voxoData={voxoData} />
          <FastNavigation voxoNav={voxoNav} />
        </>
      ) : (
        <LoadingDetailsState />
      )}
      <Footer bgLess />
    </CharacterDetailsPageWrapper>
  );
};
export default CharacterDetailsPage;
