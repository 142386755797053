import styled from 'styled-components';
import ARImage from 'assets/images/AR_Grid.png';
import media from 'themes/media';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  background: rgb(11 21 40 / 90%);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const HorizontalLine = styled.div`
  width: 65.41px;
  height: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
  margin-right: 30px;
  ${media.mobile`
   display: none;
 `}
`;
export const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  font-family: 'IBM Plex Sans', sans-serif;
  > div {
    display: flex;
    align-items: center;
    color: #ffffff;
    stroke: #ffffff;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
    &:last-child {
      font-weight: bold;
    }
  }
  ${media.mobile`
    padding: 0 10px;
    margin: 20px 0;
 `}
`;
export const Header = styled.div`
  font-family: 'Outage', sans-serif;
  font-weight: bold;
  font-size: 38px;
  line-height: 110%;
  color: #ffffff;
  text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
  text-align: center;
  margin-bottom: 50px;
  ${media.mobile`
  font-size: 24px;
    margin-bottom: 20px;
 `}
`;
export const ShowARButton = styled.div`
  position: absolute;
  width: 300px;
  height: 55px;
  left: calc(50% - 300px / 2);
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
  border-radius: 10px 2px;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  > span {
    margin-right: 16px;
  }
`;
export const ArViewerModal = styled.div`
  width: 820px;
  height: calc(100vh - 300px);
  max-height: 600px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 5px 61px rgba(225, 119, 144, 0.1);
  border-radius: 10px;
  background: #fff;
  color: #000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media screen and (max-width: 800px) {
    width: calc(100vw - 40px);
    margin: 0 20px;
  }

  .model-viewer {
    width: 100%;
    height: 611px;
    --poster-color: #ffffff00;
    background-image: url(${ARImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .attribution {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    padding: 35px 60px;
    background: #ccd9f2;
    height: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
      padding: 18px 33px;
    }
    a {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
    }
  }

  .attribution span {
    color: #5d7195;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
  }
  .attribution h1 {
    margin: 0 0 0.25em;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    color: #0e1c34;
    @media screen and (max-width: 800px) {
      font-size: 21px;
    }
  }

  .attribution img {
    opacity: 0.5;
    height: 2em;
  }

  .attribution .cc {
    flex-shrink: 0;
    text-decoration: none;
  }
`;

export const QRCodeSection = styled.div`
  position: absolute;
  right: 50px;
  bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 20px 30px;
  z-index: 10;
  align-items: center;
  text-shadow: 0px 0.698917px 43.6823px #ab9be9;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const QRCodeSectionDetailsPage = styled(QRCodeSection)`
  top: 40px;
  right: 0;
  height: max-content;
  width: max-content;
  display: none;
`;
export const QRHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: #2a4060;
  opacity: 0.4;
`;
export const QRCodeWrapper = styled.div`
  width: 65px;
  height: 65px;
  margin-top: 5px;
  > svg {
    margin: 0 !important;
  }
`;
export const QRSubHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2a4060;
  margin-top: 10px;
`;

export const CloseModalButton = styled.div`
  position: absolute;
  right: 106px;
  top: 35px;
  border-radius: 4px;
  cursor: pointer;
  width: 40px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  transition: all 0.15s ease-out;
  color: #fff;
  > svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: #ff6a84;
  }
  &:hover {
    color: #ff6a84;
  }
  ${media.mobile`
  top: 15px;
  right: 5px;
 `}
`;
