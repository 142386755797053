import {
  ascendiiList, omegaList, exomorphsList, augsList, sapiensList, robitsList,
} from '../viewer-section/viewer_list';
import angos_img from 'assets/images/factions/angos.png';
import astras_img from 'assets/images/factions/astras.png';
import corpo_img from 'assets/images/factions/corpo.png';
import verita_img from 'assets/images/factions/verita.png';

export const tabs = [{ name: 'species', title: 'SPECIES' }, {
  name: 'factions',
  title: 'FACTIONS',
}, { name: 'archetypes', title: 'ARCHETYPES' }, // { name: 'rarities', title: 'RARITIES' },
];

export const speciesMenu = [{
  name: 'omega', title: 'Omega',
}, {
  name: 'ascendii', title: 'Ascendii',
}, {
  name: 'exomorphs', title: 'Exomorphs',
}, {
  name: 'augs', title: 'Augs',
}, {
  name: 'sapiens', title: 'Sapiens',
}, {
  name: 'robits', title: 'Robits',
}];

export const species = [{
  id: 'omega',
  title: 'The omega',
  count: 1,
  counter: 6312,
  descriptions: ['No one knows what came before the Omega. This enigmatic being programmed the Cypherverse into existence before populating it with the Ascendii and then Sapiens. To the former it granted wisdom and visions of Utopia. To the latter, ambition.. and the means to mine the Cypherverse for NRG.', 'Each faction is led by a pair of Ascendii: The Shen Tanks, Corpo Vendrix, Polarion, and Luma Systemica. These four banners now mark the Cypherverse, calling followers from every corner of the nine nebula to rally behind *their* set of ideals. For the first time ever, mechanoid Augs and hybrid Exomorphs stand shoulder-to-shoulder in pursuit of something they can truly share.'],
  features: ['As the only one of its kind, The Omega is the rarest Voxo of all', 'Awoke one morning from easy dreams, transformed into luminous cubes.', 'Not immune to loneliness.'],
  stats: {
    ID: '#0000', RARITY: 'RAREST', FACTION: 'Ω', ARCHETYPE: 'DEUS',
  },
  gears: {
    Intelligence: '??', Strength: '??', Heart: '??', Cool: '??',
  },
  statsss: omegaList,
},

  {
    id: 'ascendii',
    title: 'Ascendii',
    count: 8,
    counter: 6312,
    descriptions: ['Though their names and faces have shifted through the ages, the Ascendii are, and always have been, eight beings of perfect balance. The Omega\'s departure has tilted the axis of the Cypherverse and as the fabric of existence shifts, four factions have risen up to claim new territory and precious NRG.', 'Only the Ascendii can commune directly with The Omega, though even they can’t truly know its age, gender or favourite ice cream. Recently the Omega has grown silent and unreachable. Rumours of its departure have spread through the factions of the Cypherverse. Change is afoot - because if the Omega is gone, who’s in charge now?'],
    features: ['The Ascendii don’t have a single voxel in common with any other Voxos.', 'Each Ascendii carries a VoxoCube, a gift from the Omega.'],
    stats: {
      ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
    },
    gears: {
      Intelligence: '??', Strength: '??', Heart: '??', Cool: '??',
    },
    statsss: ascendiiList,
  }, {
    id: 'exomorphs',
    title: 'Exomorphs',
    count: 32,
    counter: 6312,
    descriptions: ['Sapien records suggest that the first Exomorph was an Ape named Xavier who was accidentally charged with enough NRG to terraform a small moon. Things went surprisingly well for Xavier, though her body was lost forever and she seems to have stopped aging.', 'Years later, the Robits fashioned Xavier a magnificent mechanical body and helped her to form a small but brilliant colony of fluffy, feathery, and sometimes scaly outsiders. Morphed into not-quite-godly beings by Xavier, the Exomorphs are known for being loyal to Robits, gentle, and generally good company.'],
    features: ['Potentially immortal - only time will tell?', 'If you angle it just right, you can pet your Exo.'],
    stats: {
      ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
    },
    gears: {
      Intelligence: '??', Strength: '??', Heart: '??', Cool: '??',
    },
    statsss: exomorphsList,
  },

  {
    id: 'augs',
    title: 'Augs',
    count: 128,
    counter: 6312,
    descriptions: ['A techwear designer in the Artorian Abyss sector worked so closely with his Robit thread-spinner that people joked about them being joined at the hip. There\'d be no sense in that, but there would be sense in giving a Sapien clothier a pair of blade-hands carved from radiant metals.', 'Rituals of Sapien augmentation pervade the Cypherverse. Wherever starfall showers are commonplace, Augs will no doubt be found - collecting radiant matter and reforging themselves anew. Some do it for productivity, others purely for pleasure. For all of them, there\'s no going back.'],
    features: ['I scream, you scream, we all scream for radiant robot limbs.', 'It’s not cool to ask an Aug why they got their metal.'],
    stats: {
      ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
    },
    gears: {
      Intelligence: '??', Strength: '??', Heart: '??', Cool: '??',
    },
    statsss: augsList,
  },

  {
    id: 'sapiens',
    title: 'Sapiens',
    count: 2048,
    counter: 6312,
    descriptions: ['When the Ascendii parted ways, it was the ambitious Sapiens who hurried to prop them up and seed new societies beneath. What they lack in stature and steel body parts, the Sapiens more than make up for with grit and ingenuity.', 'They\'ve also gotten themselves *everywhere*, exploring the farthest reaches of the Cypherverse despite moving through it in a form that Robits accurately describe as ‘alarmingly frail, easily ruptured, and frustratingly dependant on oxygen\'.'],
    features: ['Not too small, not too big. Just right.', 'Would score highly if relatability was a stat here.'],
    stats: {
      ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
    },
    gears: {
      Intelligence: '??', Strength: '??', Heart: '??', Cool: '??',
    },
    statsss: sapiensList,
  }, {
    id: 'robits',
    title: 'Robits',
    count: 4095,
    counter: 6312,
    descriptions: ['From the intercity teleportation station to the vast NRG mines of planet Omnicore, the Cypherverse runs on Robit efficiency. Lesser models jettison trashcubes from tourism convoys, while the most distinguished units command entire star cruisers. Exacting tasks with programmed cheer and candour.', 'Recently, there\'s been an unusual change in the Robit-o-sphere. A handful of Ultra-Rare units appear to have spontaneously activated «laziness-modules». This has inspired an uncharacteristically slow movement that\'s spreading like oil-fire: Robits everywhere are resting, holidaying, and experimenting with a new process called <afternoon nap>.'],
    features: ['New to sleep, Robits can finally dream of electric ships.', 'A token of insurance when the Robit revolution happens.'],
    stats: {
      ID: '#????', RARITY: 'rare', FACTION: 'The Shen Tanks', ARCHETYPE: '??',
    },
    gears: {
      Intelligence: '??', Strength: '??', Heart: '??', Cool: '??',
    },
    statsss: robitsList,
  }];

export const factionsMenu = [{
  name: 'angos', title: 'The Shen Tanks',
}, {
  name: 'astras', title: 'Polarion',
}, {
  name: 'corpo', title: 'Corpo Vendrix',
}, {
  name: 'verita', title: 'Luma Systemica',
}];

export const factions = [{
  id: 'angos',
  title: 'The Shen Tanks',
  subtitle: '‘Feast on the moment, every moment a feast.’',
  description: 'Nomads, scattered through space, the galactic familiy of Shen make their way as one.**From the Heady synth-parlours of Humanica’s understreets to the neon-lit raceways of the Outer loop, Shen Tanks are a chaotic constant in the Cypherverse.**Shen, Shelterer of the Lost, formed the Tanks following a single notion:  tomorrow’s rules are not written today.**From the outside it’s easy to mistake the Shen Tanks for a rabble of ‘cypherpunks’, living fast and loose. But life with the troupe is rarely directionless, and the people it visits are always left wistful for Shen’s Phaselane lifestyle.',
  percent: '10%',
  keywords: ['Culture', 'Inclusivity', 'Family', 'Presence'],
  position: ['Rogues', 'Hedonists', 'Outcasts'],
  image: angos_img,
  features: ['The Shen Tanks are often accused of encouraging chaos in the Cypherverse, particularly by the disciplined Polarion.', 'Minimal governance is of great importance to the The Shen Tanks. Above all, they value anonymity and individual freedom.', 'The Shen Tanks have the only free NRG market. Species are free to mine and trade NRG according to their individual needs.'],
  stats: {
    ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
  },
},

  {
    id: 'astras',
    title: 'Polarion',
    subtitle: '‘Beyond the next horizon.’',
    description: 'Compelled by the spirit of adventure, service in Polarion is a covenant with discovery - a promise to be first and furthest into the void.**Called to explore, duty-bound to serve.**Charting starmaps, collecting new matter, extending the boundary of safe cypherspace.**Coming home is never easy, but a mission isn’t successful until it provides utility to the fleet. When the job is done, eyes always go back to the sky, because for Polarion, every horizon is just the beginning of the next expedition.',
    percent: '20%',
    keywords: ['Exploration', 'Betterment', 'Adventure', 'Leadership'],
    position: ['Explorers', 'Protectors'],
    image: astras_img,
    features: ['Polarion are seen as the most antagonistic faction (probably because they refer to other factions as “ground-gazing layabouts”).', 'They tenaciously seek other worlds, believing it may reunite them to their vanished creator.', 'All NRG mined in Polarion goes towards their exploratory operations.'],
    stats: {
      ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
    },
  },

  {
    id: 'corpo',
    title: 'Corpo Vendrix',
    subtitle: '‘Standing on the shoulders of gas giants.’',
    description: 'Permanence comes with a cost. One that must be shouldered by Corpo Vendrix.**The burden of industry.**Through the efforts of each and every staffer on the Vendrix datastream, the company is forged and reformed. Resigned and reimagined. Immortalised.**Expansion demands resource. Resource requires innovation. Innovation keeps the corporation ahead. **Doing what must be done today to fuel the future, that is the Corpo Vendrix method.',
    percent: '50%',
    keywords: ['Toil', 'Greatness', 'Sacrifice', 'Prosperity', 'Strategy'],
    position: ['Industry', 'Economy', 'Control'],
    image: corpo_img,
    features: ['There’s a saying that a Corpo Vendrix’ greatest talent is convincing you they’re working one angle when they’re in fact working six. Corpo Vendrix take this to be a compliment.', 'Their game is simple. Mine more NRG, no matter what it takes.', 'When NRG isn’t sold to other factions, it goes to Corpo Vendrix’ massive subterranean batteries to power the city’s expansion.'],
    stats: {
      ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
    },
  },

  {
    id: 'verita',
    title: 'Luma Systemica',
    subtitle: '‘From reflection, illumination.’',
    description: 'Look away from the light.**From the warmth and comfort of day; the bright and dazzling spectacle at the centre of the dual-star cypher.**Step gently into the calm, undisturbed shadow of moon and study the secrets of umbral tides.**Unblinded by the radiant rhythms of the universe, peer into the deep hidden truths.**Study, reflect, know.',
    percent: '20%',
    keywords: ['Wisdom', 'Patience', 'Studiousness', 'Certainty', 'Refinement'],
    position: ['Artisans', 'Educators', 'Historians'],
    features: ['Luma Systemica\'s ascetic lifestyles are scorned by the productive, expansionist Shen Tank and Corpo Vendrix factions.', 'Luma Systemica want to see the highest reality, which they believe to be achieved only through dedicated datagazing.', 'Luma Systemica has the largest number of NRG protestors who believe that overconsumption is an irreparable mistake.'],
    image: verita_img,
    stats: {
      ID: '#????', RARITY: null, FACTION: null, ARCHETYPE: null,
    },
  }];

export const raritiesMenu = [{
  name: 'rare', title: 'Rare',
}, {
  name: 'super-rare', title: 'Super-Rare',
}, {
  name: 'ultra-rare', title: 'Ultra-Rare',
}];

export const rarities = [{
  id: 'rare',
  title: 'Rare',
  percent: '80%',
  descriptions: ['All species are rare by design. The Omega, through tomes of inscrutable calculations, came to the conclusion that 6312 beings was the ideal population size for harmonious existence. species are immutable and immortal beings. There have only ever been - and will ever be - 6312 beings.'],
}, {
  id: 'super-rare',
  title: 'Super Rare',
  percent: '19%',
  descriptions: ['The Super Rare and Ultra Rare are a secret society united under a common tenant, referred to as “the One-Zero principal”. It states that the Cypherverse must return to a single society, as it was under The Omega, or face eventual collapse. Their mission is to create a new, unified protocol for the Cypherverse and eradicate the inefficiency of seperate factions.', 'Super Rare species are from all factions and archetypes, apart from the Ascendii, from whom they hide their operations. They recognise sentient Robits as equals and champion their evolution and liberation. In fact, Super Rare species act as agents within their cities to find and enlist sentient Robits to their cause.'],
}, {
  id: 'ultra-rare',
  title: 'Ultra Rare',
  percent: '1%',
  description: ['Ultra Rare species are leaders of a secret society united under a common tenant, referred to as “the One-Zero principal”. It states that the Cypherverse must return to a single society, as it was under The Omega, or face eventual collapse.', 'In order to be classed Ultra Rare, species must master the monumentally difficult feat of fluency in all protocols. This allows them to seamlessly operate across across the Shen Tanks, Polarion, Corpo Vendrix and Luma Systemica, negotiating alliances and allocating resources towards their ultimate cause: to reunite the Cypherverse’s four protocols into a single language.'],
}];
