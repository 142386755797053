import styled from 'styled-components';
import media from 'themes/media';

export const Discord = styled.span`
  padding-bottom: 4px;
  border-bottom: 1.38101px solid rgb(88 101 242 / 50%);
`;
export const DiscordImages = styled.div`
  display: flex;
  position: relative;
  bottom: 40px;
  ${media.mobile`
bottom: 60px;
`}
  > img {
    width: 451px;
    margin-right: 30px;
  }
`;
export const DiscordSection = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background: #17263e;
  margin-top: 131px;
  padding: 0 24px;
  &:hover {
    background: #1e2f4a;
    ${Discord} {
      border-bottom: 1.38101px solid #5865f2;
    }
  }
  ${media.mobile`
    flex-direction: column;
    height: 317px;
  `}
`;
export const DiscordSectionText = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 30px;
  ${media.mobile`
margin-left:0px;
bottom: 40px;
`}
  img {
    width: 31px;
    margin-right: 11px;
  }

  > span {
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    font-family: 'Outage';
    color: #8c86ff;
  }
  > div {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: bold;
    font-size: 17.2627px;
    line-height: 110%;
    display: flex;
    color: #fff;
    align-items: center;
    margin-top: 15px;
  }
`;
