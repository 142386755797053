import React, { memo, useMemo } from 'react';
import { ExtrasContainer, ExtrasList, Title } from 'containers/CharacterDetailsPage/characterDetailsPage.styled';
import ExtraItem from './ExtraItem';
import { ReactComponent as TypeCat } from 'assets/images/extra-type_pet.svg';
import { ReactComponent as TypeBackground } from 'assets/images/extra-type_background.svg';
import { ReactComponent as TypeFlora } from 'assets/images/extra-type_flora.svg';
import { ReactComponent as TypeAccessory } from 'assets/images/extra-type_accessory.svg';
import { ReactComponent as PetCollab } from 'assets/images/extra-type_pet_collab.svg';
import { ReactComponent as PetRobit } from 'assets/images/extra-type_accessory_pet_robit.svg';

const EXTRA_DATA = {
  'Extra | Pet': {
    color: '#8C86FF',
    icon: <TypeCat />,
    title: 'PET',
  },
  'Extra | Pet Collab': {
    color: '#FF6A84',
    icon: <PetCollab />,
    title: 'PET COLLAB',
  },
  'Extra | Pet Robit': {
    color: '#8C86FF',
    icon: <PetRobit />,
    title: 'PET ROBIT',
  },
  'Extra | Scene': {
    color: '#5793FF',
    icon: <TypeBackground />,
    title: 'SCENE',
  },
  'Extra | Bonsai': {
    color: '#52A496',
    icon: <TypeFlora />,
    title: 'BONSAI',
  },
  'Extra | 420': {
    color: '#FF896B',
    icon: <TypeAccessory />,
    title: '420',
  },
  'Extra | Scene Anomalous': {
    color: '#5793FF',
    icon: <TypeBackground />,
    title: 'SCENE ANOMALOUS',
  },
};

const Extras = ({ voxoData }) => {
  const extras = useMemo(
    () =>
      Object.keys(EXTRA_DATA)
        .filter(key => !!voxoData.traits[key])
        .reduce(
          (out, cur) => [
            ...out,
            {
              ...EXTRA_DATA[cur],
              value: voxoData.traits[cur],
              rarity: (voxoData.rarity.traits[cur].prevalence_in_collection * 100).toFixed(2),
              rarityPoints: voxoData.rarity.traits[cur],
            },
          ],
          [],
        ),
    [voxoData],
  );

  return extras.length ? (
    <ExtrasContainer>
      <Title>Extras</Title>
      <ExtrasList>
        {extras.length
          ? extras.map(extra => (
              <ExtraItem
                key={extra.title}
                spec={extra.value}
                rarity={extra.rarity}
                rarityPoints={extra.rarityPoints}
                data={extra}
              />
            ))
          : null}
      </ExtrasList>
    </ExtrasContainer>
  ) : null;
};
export default memo(Extras);
